import styled from "styled-components";
import { breakpoints } from "@tvg/design-system/src";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--fd-colors-border-default);
  position: relative;
`;

export const CloseSelectorWrapper = styled.section`
  @media screen and ${breakpoints.tablet.min.sm} {
    background-color: var(--fd-colors-core-white);
    position: relative;
    top: 48px;
    width: 375px;
    height: fit-content;
    padding-bottom: 0;
    border-radius: var(--fd-space-space-1);
  }
`;

export const CloseContent = styled.div`
  @media screen and ${breakpoints.tablet.min.sm} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 var(--fd-space-space-3) var(--fd-space-space-4);
    border-radius: var(--fd-space-space-1);
  }
`;

export const IconWrapper = styled.div`
  margin-left: auto;
`;
