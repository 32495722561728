import ufc from "@tvg/api/ufc";

export const getUserFavoriteTracks = (accountNumber: string) =>
  ufc.getUserAlerts(accountNumber.toString(), "track").then(({ data }) => {
    const userFavorites: Record<string, number> = {};

    const ufcFavoriteTracks = data.favorites.filter(
      (favorite) => !favorite.subscriptions.length
    );
    ufcFavoriteTracks.forEach((ufcFavorite) => {
      userFavorites[ufcFavorite.entityName] = ufcFavorite.favoriteId;
    });

    return userFavorites;
  });
