import {
  HomepageTemplate,
  SectionConfigType,
  HomepageStoryblokConfig,
  HomepageStoryblokMobileConfig
} from "@urp/homepage/src/types";
import PreferenceService from "@tvg/api/uam";
import { setNewPreferenceValue } from "@tvg/shared-actions/UserActions";
import { Dispatch } from "redux";
import { Template } from "./types";

const capitalizeWord = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const getTemplateText = (
  templatesConfig: HomepageStoryblokMobileConfig | HomepageStoryblokConfig,
  template: string,
  value: SectionConfigType
): string => {
  if (!templatesConfig || !template) return "";

  const currentTemplate = templatesConfig[
    `${template.toLowerCase()}`
  ] as HomepageTemplate;

  return (currentTemplate?.[`${value}`] as string) || "";
};

export const formatTemplate = (
  config: HomepageStoryblokMobileConfig | HomepageStoryblokConfig
): Template[] =>
  config.templates.map((template) => ({
    value: capitalizeWord(template),
    label: getTemplateText(config, template, SectionConfigType.LABEL_NAME),
    description: getTemplateText(
      config,
      template,
      SectionConfigType.DESCRIPTION
    )
  }));

export const setHPTemplateSelectorVisibility = (
  accountNumber: string,
  value: boolean,
  dispatch: Dispatch
) => {
  const config = "showHPTemplateSelector";

  return PreferenceService.setUserPref(accountNumber, config, value)
    .then(() => {
      dispatch(setNewPreferenceValue(config, `${value}`));

      return {
        success: true,
        message: "Template preference updated successfully."
      };
    })
    .catch((error: unknown) => ({
      success: false,
      message: "Failed to update template preference.",
      error
    }));
};

export const updateUserTemplatePref = async (
  accountNumber: string,
  template: string,
  isMobile: boolean
) => {
  const config = isMobile
    ? "homepageTemplateMobile"
    : "homepageTemplateDesktop";
  try {
    await PreferenceService.setUserPref(accountNumber, config, template);
    return {
      success: true,
      message: "Template preference updated successfully."
    };
  } catch (error) {
    return {
      success: false,
      message: "Failed to update template preference.",
      error
    };
  }
};
