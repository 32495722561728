import * as mediatorClassic from "@tvg/mediator-classic/src";
import tvgConf from "@tvg/conf";
import {
  createAuthTokenCookie,
  getAuthTokenCookie,
  getSportsbookRegionCookie,
  getUserSessionData,
  getUserSessionStartDate
} from "@tvg/sh-utils/sessionUtils";
import capiRequests from "@tvg/api/capi";
import { successBrazePollerRequest } from "@tvg/braze/src/store/actions";
import { attempt, get } from "lodash";
import { createConversation, events as AlchemerEvents } from "@urp/alchemer";
import {
  sessionStartUpdate,
  setRaceAlerts,
  setUserFavoriteTracksWithId
} from "@tvg/shared-actions/UserActions";
import { addCookie } from "@tvg/sh-utils/cookieUtils";
import mediator from "@tvg/mediator";
import { batch } from "react-redux";
import {
  setUserData,
  setIsVerified,
  setUserJurisdiction,
  setRaceAlertsUserId
} from "@fdr/shared-actions/UserActions";
import {
  isMobile,
  isXSell,
  onTriggerGeolocation
} from "@tvg/sh-utils/mobileUtils";
import sendToAppsFlyer from "@tvg/utils/appsflyerUtils";
import {
  errorLocationSplashAction,
  resetStateLocationSplashAction
} from "@urp/location-splash/src/actions";
import {
  clearCallback,
  triggerGlobalCallback
} from "@tvg/sh-utils/callbackUtils";
import CPPService from "@tvg/sh-cpp";
import { storeCPPPromotions } from "@tvg/sh-cpp/src/service/redux/actions";

import { getUserCompliance } from "../../../../../../utils/account/getUserCompliance";
import { getUserProfile } from "../getProfile";
import { getBalance } from "../../../../../../utils/wallet/getBalance";
import { getUserJurisdictionByAccount } from "../getUserJurisdictionByAccount";
import { type CompleteLoginProps, LoginError } from "./types";
import type { UserInfo } from "../../../../../../types/account/user";
import { getUserRaceAlerts } from "../../../../../../utils/favorites/services/getUserRaceAlerts";
import { getUserFavoriteTracks } from "../../../../../../utils/favorites/services/getUserFavoriteTracks";
import { setUserFavoriteTracks } from "../../../../../../utils/favorites/actions";
import { openLoginSuccessGtm } from "../../../../../../utils/account/gtm";
import { logout } from "../../../../../../utils/account";

export const completeLogin = async ({
  accountUser,
  session,
  dispatch,
  errorCode,
  enforceAcceptTerms,
  userFavoriteTracksLoaded,
  enableCPPPromos
}: CompleteLoginProps) => {
  try {
    const cppService = new CPPService();
    if (errorCode !== LoginError.GhostAccount) {
      dispatch(resetStateLocationSplashAction());
    }

    if (enableCPPPromos) {
      cppService.getPromos().then((cppPromos) =>
        // @ts-ignore
        dispatch(storeCPPPromotions(cppPromos || []))
      );
    }

    if (accountUser && session) {
      const region = getSportsbookRegionCookie();

      const oldAuthToken = getAuthTokenCookie();
      const { sessionId: oldSessionId, fdUserId: oldUserId } = oldAuthToken
        ? getUserSessionData(oldAuthToken)
        : { sessionId: "", fdUserId: "" };

      // Remove all buttons in the app about the login to prevent the user try to start the session again.
      if (
        session.sessionId?.toString() !== oldSessionId.toString() ||
        accountUser.userId?.toString() !== oldUserId.toString()
      ) {
        dispatch(
          setUserData({
            logged: undefined
          })
        );
      }

      if (session.token !== oldAuthToken && session.token !== undefined) {
        createAuthTokenCookie(session.token);

        if (session.sessionId.toString() !== oldSessionId.toString()) {
          onTriggerGeolocation(!oldSessionId ? "Normal" : "Retry");
        }
      }

      if (session.token !== undefined) {
        try {
          const [userProfile, jurisdiction] = await Promise.all([
            getUserProfile(accountUser, region || "", dispatch),
            getUserJurisdictionByAccount(accountUser, session, region)
          ]);

          try {
            const brazePollerTimeResponse =
              await capiRequests.fetchBrazePollerTime();
            const brazePollerTime = get(brazePollerTimeResponse, "data.value");
            if (brazePollerTime) {
              dispatch(successBrazePollerRequest(brazePollerTime));
            }
          } catch (brazeError) {
            console.warn("[UM] Account: Braze error:", brazeError);
          }

          const user =
            get(userProfile, "user") ||
            ({
              userName: "",
              profile: "",
              accountNumber: "",
              lastName: "",
              firstName: "",
              emailAddress: "",
              fullAvatarUrl: ""
            } as UserInfo);

          const isVerified = get(userProfile, "isVerified", false);

          // @ts-ignore -> Looks like wrong type on Create Conversation, we need to check this.
          createConversation({
            ...user,
            accountNumber: user.fdUserId as string
          });
          AlchemerEvents.login();
          dispatch(sessionStartUpdate(getUserSessionStartDate(session.token)));

          if (isMobile()) {
            // get all race alerts subscriptions from ufc service
            try {
              const raceAlertsList = await getUserRaceAlerts(
                user.accountNumber,
                "race"
              );
              dispatch(setRaceAlerts(raceAlertsList));
            } catch (e) {
              dispatch(setRaceAlerts([]));
            }
          }

          if (isVerified) {
            if (!userFavoriteTracksLoaded) {
              try {
                const favoriteTracks = await getUserFavoriteTracks(
                  user.accountNumber
                );
                const actualFavoriteTracks = Object.keys(favoriteTracks);
                dispatch(setUserFavoriteTracks(actualFavoriteTracks));
                dispatch(setUserFavoriteTracksWithId(favoriteTracks));
                mediator.base.dispatch({ type: "GOT_FAVORITE_TRACKS" });
              } catch (e) {
                console.warn("[UM] Account: Error getting favorite tracks", e);
              }
            }
          }

          // Storage Wager Profile
          addCookie("wp", user.profile);
          addCookie("hasLoginOnce", "true", 31622400);
          if (typeof window !== "undefined") {
            window.sessionStorage.setItem("userId", user.accountNumber);
          }

          mediator.base.dispatch({
            type: "FETCH_QUICKLINKS"
          });

          batch(() => {
            dispatch(
              setUserData({
                logged: true,
                userLogout: false,
                hasRequested: true,
                // @ts-ignore -> Partial user
                user
              })
            );
            dispatch(setIsVerified(isVerified));
            dispatch(setUserJurisdiction(jurisdiction));

            if (
              tvgConf().device === "desktop" ||
              tvgConf().product === "tvg4"
            ) {
              mediatorClassic.dispatch("TVG_LOGIN:USER_SESSION_UPDATE", {
                user: {
                  ...user,
                  status: "Success"
                },
                logged: true,
                hasRequested: true
              });
            }
          });

          const isTermsAccepted = get(jurisdiction, "accepted", false);
          const accountNumber = get(user, "accountNumber", "0");

          if (
            (isXSell() || enforceAcceptTerms) &&
            isVerified &&
            !isTermsAccepted
          ) {
            getUserCompliance();
          }

          getBalance(dispatch, accountNumber);

          openLoginSuccessGtm(user, jurisdiction, isVerified);

          if (isVerified && isTermsAccepted) {
            triggerGlobalCallback(user);
          } else {
            clearCallback();
          }

          // Send AppsFlyer login event for iOS app
          sendToAppsFlyer({
            key: "af_login",
            values: {
              accountId: accountNumber
            }
          });

          mediator.ios.dispatch({
            type: "PUSHWOOSH_EVENTS",
            payload: {
              pushwooshTags: {
                HasLoggedInOnce:
                  attempt(() => localStorage.getItem("userLoginOnce")) || false,
                StateOfRegistration: get(user, "homeAddress.state", ""),
                FirstName: get(user, "firstName", ""),
                AccountNumber: accountNumber
              }
            }
          });

          dispatch(setRaceAlertsUserId(user.accountNumber));
        } catch (reqError) {
          console.warn("[UM] Account: Complete login error:", reqError);
          clearCallback();

          if (!isXSell()) {
            dispatch(errorLocationSplashAction("DEFAULT_ERROR"));
          }
          throw reqError;
        }
      }
    } else {
      dispatch(
        setUserData({
          logged: false
        })
      );
    }
  } catch (e) {
    console.warn("[UM] Account: Error on session", e);

    // Login can be wrong in the middle of the process, so is better to clean the session.
    logout();
  }
};
