import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const Container = styled.li<{ isActive: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  padding: var(--fd-space-space-4) 0;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -12px;
    right: -12px;
    height: 1px;
    background-color: var(--fd-colors-border-subtle);
  }

  @media screen and ${breakpoints.tablet.max.sm} {
    &:first-child {
      padding-top: 2px;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

export const CircleRadio = styled.div<{ isActive: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid var(--fd-colors-border-default);
  background-color: ${({ isActive }) =>
    isActive ? "var(--fd-colors-core-blue)" : "transparent"};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isActive }) =>
    isActive &&
    css`
      border-color: var(--fd-colors-core-blue);

      &::after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: var(--fd-colors-core-white);
        border-radius: 50%;
      }
    `}
`;

export const HiddenRadio = styled.input.attrs({ type: "radio" })`
  display: none;
`;

export const StyledLabel = styled.label<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;
