import { UserJurisdiction, User } from "@fdr/types/ts/User";

type UserUpdateAction = { type: "USER_DATA_UPDATE"; payload: Partial<User> };

export type UserLogout = {
  type: "USER_LOGOUT";
};

export type UserTermsAndConditions = {
  type: "SET_USER_TERMS_AND_CONDITIONS";
  payload: {
    isTermsAccepted: boolean;
  };
};

export type SetIsVerified = {
  type: "SET_IS_VERIFIED";
  payload: {
    isVerified: boolean;
  };
};

export type SetUserJurisdiction = {
  type: "SET_USER_JURISDICTION";
  payload: {
    jurisdiction: UserJurisdiction | null;
  };
};

export type OpenInvalidResidentialAddressModal = {
  type: "OPEN_INVALID_RESIDENTIAL_ADDRESS_MODAL";
};

export type CloseInvalidResidentialAddressModal = {
  type: "CLOSE_INVALID_RESIDENTIAL_ADDRESS_MODAL";
};

export type InvalidResidentialAddressModalActions =
  | OpenInvalidResidentialAddressModal
  | CloseInvalidResidentialAddressModal;

export type Actions =
  | UserUpdateAction
  | UserLogout
  | SetIsVerified
  | SetUserJurisdiction
  | InvalidResidentialAddressModalActions;

export type SetRaceAlertsUserId = {
  type: "SET_RACE_ALERTS_USER_ID";
  payload: string;
};

export const setUserData = (userData: Partial<User>): UserUpdateAction => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const setIsLogged = (
  isLogged: boolean
): { type: string; payload: boolean } => ({
  type: "SET_IS_LOGGED",
  payload: isLogged
});

export const logoutUser = (): UserLogout => ({
  type: "USER_LOGOUT"
});

export const setIsVerified = (isVerified: boolean): SetIsVerified => ({
  type: "SET_IS_VERIFIED",
  payload: {
    isVerified
  }
});

export const setUserJurisdiction = (
  jurisdiction: UserJurisdiction | null
): SetUserJurisdiction => ({
  type: "SET_USER_JURISDICTION",
  payload: {
    jurisdiction
  }
});

export const setUserTermsAndCondition = (
  isTermsAccepted: boolean
): UserTermsAndConditions => ({
  type: "SET_USER_TERMS_AND_CONDITIONS",
  payload: {
    isTermsAccepted
  }
});

export const openInvalidResidentialAddressModal =
  (): OpenInvalidResidentialAddressModal => ({
    type: "OPEN_INVALID_RESIDENTIAL_ADDRESS_MODAL"
  });

export const closeInvalidResidentialAddressModal =
  (): CloseInvalidResidentialAddressModal => ({
    type: "CLOSE_INVALID_RESIDENTIAL_ADDRESS_MODAL"
  });

export const setRaceAlertsUserId = (userId: string): SetRaceAlertsUserId => ({
  type: "SET_RACE_ALERTS_USER_ID",
  payload: userId
});
