import { getSportsbookRegionCookie } from "@tvg/sh-utils/sessionUtils";
import { getUserProfile } from "../getProfile";
import { completeLogin } from "../completeLogin";
import { UpdateUserDetailsProps } from "./types";

export const updateUserDetails = ({
  user,
  session,
  dispatch,
  isVerified,
  enforceAcceptTerms,
  enableCPPPromos
}: UpdateUserDetailsProps) => {
  if (isVerified) {
    getUserProfile(user, getSportsbookRegionCookie(), dispatch);
  } else {
    completeLogin({
      accountUser: user,
      session,
      dispatch,
      enforceAcceptTerms,
      enableCPPPromos
    });
  }
};
