import { get } from "lodash";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import mediatorScoped from "../../../../../../../utils/mediator";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import type { VerifyUserPayload } from "./types";
import type { AccountModule } from "../../../../../../useLoadModule/types";

export const webVerifyUserSubscription = (accountInstance: AccountModule) => {
  const event = getUMEvent(UMEvents.Verification);

  mediatorScoped.subscribe(event.trigger, async (data: VerifyUserPayload) => {
    const isVerified = get(data, "payload.isVerified", false);
    const showUI = get(data, "payload.showUI", false);
    const needsUpdate = !isVerified && showUI;

    if (showUI) {
      await accountInstance.verifyUserAsync();
    }

    mediatorScoped.dispatch({
      type: event.completion,
      payload: {
        isVerified,
        needsUpdate
      }
    });
  });
};
