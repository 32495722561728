import {
  SetHomepageTemplates,
  SetTemplateUser,
  SetHomepageMobileTemplates
} from "./actions";

export type State = {
  templates: string[];
};

export const initialState = {
  templates: [] as string[]
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: State = initialState,
  action: SetHomepageTemplates | SetTemplateUser | SetHomepageMobileTemplates
) => {
  switch (action.type) {
    case "SET_HOMEPAGE_TEMPLATES_ACTION":
      return {
        ...state,
        ...action.payload
      };
    case "SET_HOMEPAGE_MOBILE_TEMPLATES_ACTION":
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
