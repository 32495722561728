import mediator from "../../mediator";
import { UMEvents } from "../../mediator/types";
import { getUMEvent } from "../../mediator/events";

export const firstLoad = () => {
  const event = getUMEvent(UMEvents.FirstLoad);

  mediator.dispatch({
    type: event.trigger
  });
};
