import React, { MutableRefObject } from "react";
import { get } from "lodash";
import {
  ActionItemTypes,
  IModalPresenter
} from "@fanduel/core-modal-presenter-contract";
import { ModalWebView } from "./modalWebView";
import mediator from "../../../../../../../utils/mediator";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import {
  AccountModule,
  CoreWebviewModule
} from "../../../../../../useLoadModule/types";
import type { ExtraProps, WebModalPresenterPayload } from "./types";
import type { UMPages } from "../../../../../../../utils/modalPresenter/presentUMModal/types";

export const webModalPresenterSubscription = (
  modalPresenter: IModalPresenter,
  CoreWebView: CoreWebviewModule,
  accountInstance: AccountModule,
  extraProps: MutableRefObject<Partial<ExtraProps>>
) => {
  const event = getUMEvent(UMEvents.PresentModal);

  mediator.subscribe(event.trigger, (data: WebModalPresenterPayload) => {
    const source: UMPages | undefined = get(data, "payload.url");
    const region: string | undefined = get(
      data,
      "payload.region",
      extraProps?.current.region
    );
    const titleText: string | undefined = get(data, "payload.title");
    const fetchDependencies = get(data, "payload.fetchDependencies", {});

    if (source && region) {
      const modalRef = modalPresenter.presentModal(
        {
          titleText,
          trailingActionItem: {
            type: ActionItemTypes.CLOSE,
            onClick: () => {
              modalRef?.dismiss();
            }
          }
        },
        <ModalWebView component={CoreWebView} source={source} />
      );

      if (modalRef) {
        modalRef.setOnDismiss(async () => {
          const session = await accountInstance.getSessionAsync("Silent");
          const user = await accountInstance.getUserAsync("Local");

          mediator.dispatch({
            type: event.completion,
            payload: {
              user,
              session,
              needToFetchUserDetails: get(
                fetchDependencies,
                "needToFetchUserDetails",
                false
              ),
              needToFetchBalance: get(
                fetchDependencies,
                "needToFetchBalance",
                false
              )
            }
          });
        });
      }
    }
  });
};
