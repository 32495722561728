import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { Paragraph, Button } from "@tvg/design-system/web";
import {
  IconButton,
  Modal,
  useOnClickOutside,
  Tooltip
} from "@tvg/design-system/src";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomepageConfigurableToggle,
  getHomepageTemplateUserPrefDesktop,
  getHomepageTemplateUserPrefMobile,
  getShowHPTemplateSelector
} from "@tvg/sh-lib-preferences/redux/selectors";
import { getAccountNumber } from "@urp/store-selectors";
import { useAllowChoseBuiltInProfile } from "@urp/amplitude-experiments";
import {
  setUserPrefsHomepageTemplateMobile,
  setUserPrefsHomepageTemplateDesktop
} from "@tvg/sh-lib-account-actions/src/actions/login";
import { NullaryFn } from "@tvg/ts-types/Functional";
import {
  getTemplateText,
  updateUserTemplatePref,
  setHPTemplateSelectorVisibility
} from "@tvg/pref/src/components/HomepageConfig/utils";
import { isXSell } from "@tvg/sh-utils/isValidSessionByToken";
import {
  getHomepageTemplates,
  getUserExperienceProfileSelector,
  getCloseModalMessage
} from "../../store/selectors";
import {
  Container,
  ButtonContainer,
  CloseContainer,
  TemplatesContainer,
  IconExperiment,
  HeaderContainer,
  CloseModalBackground
} from "./styled-components";
import { VIEW_TYPE_SELECTOR } from "./types";
import TemplateSelectorIcon from "../../icons/TemplateSelectorIcon";
import { SectionConfigType } from "../../types";
import { TemplateList } from "./TemplateList";
import { CloseModalContent } from "./CloseModal";

interface Props {
  isMobile?: boolean;
  viewTypeSelector?: VIEW_TYPE_SELECTOR;
  onSettingsClick?: NullaryFn<void>;
  onDismiss?: NullaryFn<void>;
}

function HPTemplateSelector({
  isMobile = false,
  viewTypeSelector = VIEW_TYPE_SELECTOR.BANNER,
  onSettingsClick,
  onDismiss
}: Props) {
  const currentLocation = useLocation();
  const templatesConfig = useSelector(getHomepageTemplates);
  const userExperienceProfileSelector = useSelector(
    getUserExperienceProfileSelector
  );
  const allowChoseProfile = useAllowChoseBuiltInProfile();
  const isHomepageConfigurable = useSelector(getHomepageConfigurableToggle);
  const dispatch = useDispatch();
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const closeModalMessages = useSelector(getCloseModalMessage);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setIsTemplatesModalOpen(false);
    setIsCloseModalOpen(false);
  });

  const userPrefTemplate = useSelector(
    isMobile
      ? getHomepageTemplateUserPrefMobile
      : getHomepageTemplateUserPrefDesktop
  );

  const prefShowHPTemplateSelector = useSelector(getShowHPTemplateSelector);

  const selectedTemplate = userPrefTemplate.toLowerCase() || "default";
  const accountNumber = useSelector(getAccountNumber);

  const [newSelectedTemplate, setNewSelectedTemplate] =
    useState<string>(selectedTemplate);

  useEffect(() => {
    setNewSelectedTemplate(selectedTemplate);
  }, [isTemplatesModalOpen, selectedTemplate]);

  const handleOnChange = async (template: string) => {
    try {
      if (template === userPrefTemplate) return;

      const result = await updateUserTemplatePref(
        accountNumber,
        template,
        isMobile
      );

      if (result.success) {
        if (isMobile) {
          dispatch(setUserPrefsHomepageTemplateMobile(template));
        } else {
          dispatch(setUserPrefsHomepageTemplateDesktop(template));
        }
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error(
        "An unexpected error occurred during updating of template preference:",
        error
      );
    }
  };

  const handleDismiss = () => {
    setIsDismissed(true);
    if (typeof onDismiss === "function") onDismiss();
  };

  const handleOnSettingsClick = () => {
    if (typeof onSettingsClick === "function") onSettingsClick();
  };

  const handleCloseModal = () => {
    setIsCloseModalOpen(false);
  };

  const renderBannerSelector = () => {
    // TODO: remove this condition after user session experiment is completed
    if (isMobile && !get(userExperienceProfileSelector, "FDR_SA.show_banner")) {
      return null;
    }
    // TODO: remove this condition after user session experiment is completed
    if (
      !isMobile &&
      !get(userExperienceProfileSelector, "FDR_DESK.show_banner")
    )
      return null;

    return (
      <Container isDismissed={isDismissed} data-qa-label="hp-template-selector">
        <Paragraph
          fontSize="s"
          color="--fd-colors-core-white"
          qaLabel="qaLabel"
          marginRight="8px"
        >
          {isMobile
            ? "Select your homepage profile"
            : "Select the profile you want to see on your homepage"}
        </Paragraph>
        <ButtonContainer>
          <Button
            variant="secondaryDark"
            onClick={() => setIsTemplatesModalOpen((prevState) => !prevState)}
            qaLabel="hp-template-selector-button"
            iconPosition="end"
            icon={
              isTemplatesModalOpen && !isMobile ? "chevronUp" : "chevronDown"
            }
            size={isMobile ? "s" : "m"}
          >
            {getTemplateText(
              templatesConfig,
              selectedTemplate,
              SectionConfigType.LABEL_NAME
            )}
          </Button>
          {renderDeskModal()}
        </ButtonContainer>
        <IconButton
          iconName="settings"
          qaLabel="hp-template-selector-settings"
          variant="tertiary"
          tertiaryColor="white.900"
          size="s"
          onPress={handleOnSettingsClick}
        />
        {!isXSell() && (
          <CloseContainer>
            <IconButton
              iconName="close"
              qaLabel="hp-template-selector-close"
              variant="tertiary"
              tertiaryColor="white.900"
              size="s"
              onPress={() => setIsCloseModalOpen((prevState) => !prevState)}
            />
            {!isMobile && isCloseModalOpen && (
              <CloseModalBackground onClick={handleCloseModal}>
                <CloseModalContent
                  isOpen={isCloseModalOpen}
                  setIsOpen={setIsCloseModalOpen}
                  onClose={handleCloseModal}
                  onDismiss={handleDismiss}
                  handleHPTemplateSelectorVisibility={
                    handleHPTemplateSelectorVisibility
                  }
                  prefShowHPTemplateSelector={prefShowHPTemplateSelector}
                  title={closeModalMessages.title}
                  description={closeModalMessages.description}
                  isMobile={isMobile}
                  setIsTemplatesModalOpen={setIsTemplatesModalOpen}
                />
              </CloseModalBackground>
            )}
          </CloseContainer>
        )}
      </Container>
    );
  };

  const renderIconSelector = () => {
    // this is only visible on homepage
    if (!["/home", "/"].includes(currentLocation.pathname)) {
      return null;
    }
    // TODO: remove this condition after user session experiment is completed
    if (isMobile && !get(userExperienceProfileSelector, "FDR_SA.show_icon")) {
      return null;
    }
    // TODO: remove this condition after user session experiment is completed
    if (!isMobile && !get(userExperienceProfileSelector, "FDR_DESK.show_icon"))
      return null;

    if (!prefShowHPTemplateSelector) {
      return null;
    }

    return (
      <ButtonContainer>
        <Tooltip
          qaLabel="tooltip"
          content="Profile Selector"
          placement="bottom-mid"
          isDarkMode
          isDisabled={isMobile || isTemplatesModalOpen}
        >
          <IconExperiment
            data-qa-label="hp-template-selector-icon"
            onClick={() => setIsTemplatesModalOpen((prevState) => !prevState)}
          >
            <TemplateSelectorIcon />
          </IconExperiment>
        </Tooltip>

        {!isMobile && renderDeskModal()}
      </ButtonContainer>
    );
  };

  const renderDeskModal = () => {
    if (!isTemplatesModalOpen || isMobile) {
      return null;
    }
    return (
      <>
        <TemplatesContainer ref={ref} onClick={(e) => e.stopPropagation()}>
          <HeaderContainer>
            <Paragraph
              fontSize="l"
              fontFamily="bold"
              color="--fd-colors-content-strong"
              marginBottom="8px"
              padding="var(--fd-space-space-3)"
              qaLabel="hp-template-selector-title"
            >
              Homepage Profiles
            </Paragraph>
          </HeaderContainer>
          <TemplateList
            templatesConfig={templatesConfig}
            selectedTemplate={newSelectedTemplate}
            getTemplateText={getTemplateText}
            onTemplateSelect={(template: string) =>
              setNewSelectedTemplate(template)
            }
            onApply={() => {
              handleOnChange(newSelectedTemplate);
              setIsTemplatesModalOpen(false);
            }}
            isApplyDisabled={selectedTemplate === newSelectedTemplate}
          />
        </TemplatesContainer>
      </>
    );
  };

  const handleHPTemplateSelectorVisibility = () => {
    setHPTemplateSelectorVisibility(
      accountNumber,
      !prefShowHPTemplateSelector,
      dispatch
    );
  };

  if (!isHomepageConfigurable && !allowChoseProfile) {
    return null;
  }

  return (
    <>
      {viewTypeSelector === VIEW_TYPE_SELECTOR.BANNER
        ? renderBannerSelector()
        : renderIconSelector()}
      {isMobile && isTemplatesModalOpen && (
        <Modal
          isOpen={isTemplatesModalOpen || isCloseModalOpen}
          title="Homepage Profiles"
          type="flexible"
          qaLabel="hp-template-selector-modal"
          onClose={() => {
            setIsTemplatesModalOpen(false);
          }}
          onOverlayClick={() => {
            setIsTemplatesModalOpen(false);
          }}
        >
          <TemplatesContainer ref={ref} onClick={(e) => e.stopPropagation()}>
            <TemplateList
              templatesConfig={templatesConfig}
              selectedTemplate={newSelectedTemplate}
              getTemplateText={getTemplateText}
              onTemplateSelect={(template) => setNewSelectedTemplate(template)}
              onApply={() => {
                handleOnChange(newSelectedTemplate);
                setIsTemplatesModalOpen(false);
              }}
              isApplyDisabled={selectedTemplate === newSelectedTemplate}
            />
          </TemplatesContainer>
        </Modal>
      )}
      {/* //TODO: refactor to avoid to duplicate the modal */}
      {isMobile && isCloseModalOpen && (
        <Modal
          isOpen={isTemplatesModalOpen || isCloseModalOpen}
          title={closeModalMessages.title}
          type="flexible"
          qaLabel="hp-template-selector-modal"
          onClose={handleCloseModal}
          onOverlayClick={handleCloseModal}
        >
          <div ref={ref} onClick={(e) => e.stopPropagation()}>
            <CloseModalContent
              isOpen={isCloseModalOpen}
              setIsOpen={setIsCloseModalOpen}
              onClose={handleCloseModal}
              onDismiss={handleDismiss}
              handleHPTemplateSelectorVisibility={
                handleHPTemplateSelectorVisibility
              }
              prefShowHPTemplateSelector={prefShowHPTemplateSelector}
              title={closeModalMessages.title}
              description={closeModalMessages.description}
              isMobile={isMobile}
              setIsTemplatesModalOpen={setIsTemplatesModalOpen}
            />
          </div>
        </Modal>
      )}
    </>
  );
}

export default HPTemplateSelector;
