import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { noop } from "lodash";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import { openPreferencesModalAction } from "@tvg/sh-lib-preferences/redux/actions";
import { getIsLogged } from "@urp/store-selectors";
import { setShowStateSelector } from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import {
  events as AlchemerEvents,
  trackBetaFeatureFeedback
} from "@urp/alchemer";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { openUserHomepage } from "@urp/unified-modules/src/utils/account/openUserHomepage";
import { presentUMModal } from "@urp/unified-modules/src/utils/modalPresenter/presentUMModal";
import { UMPages } from "@urp/unified-modules/src/utils/modalPresenter/presentUMModal/types";
import { getUserCompliance } from "@urp/unified-modules/src/utils/account/getUserCompliance";
import { openWalletScreen } from "@urp/unified-modules/src/utils/wallet/openScreen";
import { UMEventsWallet } from "@urp/unified-modules/src/utils/mediator/types";
import { getSession } from "@urp/unified-modules/src/utils/account";
import { setGlobalCallback } from "@tvg/sh-utils/callbackUtils";

import {
  openContentMoreGtm as contentNavigationCallBack,
  moreLinksGtm as navigationCallBack,
  onExternalLinkCallback,
  navigateTutorialsGtm,
  accountLinksGtm
} from "../utils";
import { GlobalMenuWalletData, MenuLinkProps, MenuRoutesProps } from "../types";

interface UseMenuRoutesReturn {
  accountLinks: MenuLinkProps[];
  accountOverviewLinks: MenuLinkProps[];
  otherLinks: MenuLinkProps[];
}

// If accessing the Account Page doesn't require a login,
// there's a need to protect certain links with a prior login form
const handleLinkNeedingPriorLogin = (
  noLoginRequiredForAccountPage: boolean,
  isLogged: boolean,
  onClickHandler: NullaryFn<void>
) => {
  if (!isLogged && noLoginRequiredForAccountPage) {
    getSession("Normal", onClickHandler);
  } else {
    onClickHandler();
  }
};

export function useGwMenuRoutes({
  derbyGuide,
  isTutorialsLinkNew,
  doNotSell,
  accountNumber,
  termsConditions,
  xSellMigrationDate,
  talentPicks,
  watchFDTV,
  enableNoLoginRequiredForAccountPage,
  enablePreferencesCenter,
  onTransactionHistoryClick = noop,
  globalWalletMenuData,
  enableURPEducationHub
}: MenuRoutesProps): UseMenuRoutesReturn {
  const isLogged = useSelector(getIsLogged);
  const dispatch = useDispatch();
  const showAWTransactionHistory = xSellMigrationDate;
  const noLoginRequiredForAccountPage =
    enableNoLoginRequiredForAccountPage && !isXSell();
  const globalMenuConfig = globalWalletMenuData as GlobalMenuWalletData;

  const accountLinks = useMemo<MenuLinkProps[]>(
    () => [
      {
        qaLabel: "account-nav-balance",
        to: globalMenuConfig.balance.link,
        onClick: () => {
          openWalletScreen(UMEventsWallet.AccountHome);
          accountLinksGtm(
            globalMenuConfig.balance.link,
            globalMenuConfig.balance.label
          );
        },
        linkText: globalMenuConfig.balance.label,
        isExternalLink: globalMenuConfig.balance.isExternal
      },
      {
        qaLabel: "account-nav-transactionHistory",
        to: globalMenuConfig.transactionsHistory.link,
        onClick: accountNumber
          ? () => {
              onTransactionHistoryClick();
              contentNavigationCallBack(
                showAWTransactionHistory
                  ? "Transactions before"
                  : globalMenuConfig.transactionsHistory.label
              );
            }
          : getUserCompliance,
        linkText: showAWTransactionHistory
          ? `Transactions before ${xSellMigrationDate}`
          : globalMenuConfig.transactionsHistory.label,
        isExternalLink: globalMenuConfig.transactionsHistory.isExternal
      },
      ...(showAWTransactionHistory
        ? [
            {
              qaLabel: "account-nav-transactionHistoryAWFlows",
              to: globalMenuConfig.transactionHistoryAW.link,
              onClick: () => {
                openWalletScreen(UMEventsWallet.Transactions);
                contentNavigationCallBack(
                  globalMenuConfig.transactionHistoryAW.label
                );
                accountLinksGtm(
                  globalMenuConfig.transactionHistoryAW.link,
                  globalMenuConfig.transactionHistoryAW.label
                );
              },
              linkText:
                globalMenuConfig.transactionHistoryAW.label +
                `${xSellMigrationDate}`,
              isExternalLink: globalMenuConfig.transactionHistoryAW.isExternal
            }
          ]
        : []),
      {
        qaLabel: "account-nav-taxInformation",
        to: globalMenuConfig.taxCenter.link,
        onClick: () => {
          openWalletScreen(UMEventsWallet.Tax);
          accountLinksGtm(
            globalMenuConfig.taxCenter.link,
            globalMenuConfig.taxCenter.label
          );
        },
        linkText: globalMenuConfig.taxCenter.label,
        isExternalLink: globalMenuConfig.taxCenter.isExternal
      },
      ...(enablePreferencesCenter
        ? [
            {
              qaLabel: "account-preferences",
              to: globalMenuConfig.preferences.link,
              onClick: () => {
                dispatch(openPreferencesModalAction());
                accountLinksGtm(
                  globalMenuConfig.preferences.link,
                  globalMenuConfig.preferences.label
                );
              },
              linkText: globalMenuConfig.preferences.label,
              isExternalLink: globalMenuConfig.preferences.isExternal
            }
          ]
        : []),
      {
        qaLabel: "account-activity-statement",
        to: globalMenuConfig.activityStatement.link,
        onClick: () => {
          openWalletScreen(UMEventsWallet.Activity);
          accountLinksGtm(
            globalMenuConfig.activityStatement.link,
            globalMenuConfig.activityStatement.label
          );
        },
        linkText: globalMenuConfig.activityStatement.label,
        isExternalLink: globalMenuConfig.activityStatement.isExternal
      },
      {
        qaLabel: "account-my-spend",
        to: globalMenuConfig.mySpend.link,
        onClick: () => {
          presentUMModal(UMPages.MY_SPEND);
          accountLinksGtm(
            globalMenuConfig.mySpend.link,
            globalMenuConfig.mySpend.label
          );
        },
        linkText: globalMenuConfig.mySpend.label,
        isExternalLink: globalMenuConfig.mySpend.isExternal
      }
    ],
    [JSON.stringify(globalMenuConfig), accountNumber]
  );

  const accountOverviewLinks = useMemo<MenuLinkProps[]>(
    () => [
      {
        qaLabel: "account-nav-settings",
        to: globalMenuConfig.settings.link,
        onClick: () => {
          openUserHomepage();
          accountLinksGtm(
            globalMenuConfig.settings.link,
            globalMenuConfig.settings.label
          );
        },
        linkText: globalMenuConfig.settings.label,
        isExternalLink: globalMenuConfig.settings.isExternal
      }
    ],
    [accountNumber, globalMenuConfig.settings.link]
  );

  const otherLinks = useMemo<MenuLinkProps[]>(
    () => [
      ...(derbyGuide?.toggle &&
      globalMenuConfig.derbyBetting?.url &&
      globalMenuConfig.derbyBetting.label
        ? [
            {
              qaLabel: "account-nav-derby-betting",
              to: globalMenuConfig.derbyBetting.link,
              onClick: (e: Event) => {
                onExternalLinkCallback(
                  globalMenuConfig.derbyBetting?.url || "",
                  e
                );
                navigationCallBack(
                  globalMenuConfig.derbyBetting?.url || "",
                  globalMenuConfig.derbyBetting.label
                );
              },
              linkText: globalMenuConfig.derbyBetting.label,
              isExternalLink: globalMenuConfig.derbyBetting.isExternal
            }
          ]
        : []),
      ...(talentPicks.toggle
        ? [
            {
              qaLabel: "account-nav-talent-picks",
              to: globalMenuConfig.talentPicks.link,
              onClick: () =>
                navigationCallBack(
                  globalMenuConfig.talentPicks.link,
                  globalMenuConfig.talentPicks.label
                ),
              linkText: globalMenuConfig.talentPicks.label,
              isExternalLink: globalMenuConfig.talentPicks.isExternal
            }
          ]
        : []),
      ...(watchFDTV.toggle
        ? [
            {
              qaLabel: "account-nav-watch-fdtv",
              to: globalMenuConfig.watchFDTV.link,
              onClick: () =>
                navigationCallBack(
                  globalMenuConfig.watchFDTV.link,
                  globalMenuConfig.watchFDTV.label
                ),
              linkText: globalMenuConfig.watchFDTV.label,
              isExternalLink: globalMenuConfig.watchFDTV.isExternal
            }
          ]
        : []),
      ...(!isXSell()
        ? [
            {
              qaLabel: "account-nav-tracks-information-list",
              to: globalMenuConfig.trackInformationList.link,
              onClick: () =>
                navigationCallBack(
                  globalMenuConfig.trackInformationList.link,
                  globalMenuConfig.trackInformationList.label
                ),
              linkText: globalMenuConfig.trackInformationList.label,
              isExternalLink: globalMenuConfig.trackInformationList.isExternal
            }
          ]
        : []),
      {
        qaLabel: "account-nav-FAQ",
        to: globalMenuConfig.faq.link,
        onClick: (e: Event) => {
          onExternalLinkCallback(globalMenuConfig.faq?.url || "", e);
          navigationCallBack(
            globalMenuConfig.faq?.url || "",
            globalMenuConfig.faq.label
          );
        },
        linkText: globalMenuConfig.faq.label,
        isExternalLink: globalMenuConfig.faq.isExternal
      },
      ...(enableURPEducationHub
        ? [
            {
              qaLabel: "account-nav-tutorials",
              to: globalMenuConfig.educationHub.link,
              onClick: () => {
                navigateTutorialsGtm(globalMenuConfig.educationHub.link);
                AlchemerEvents.selectTutorialsTab();
              },
              linkText: globalMenuConfig.educationHub.label,
              highlightedText: isTutorialsLinkNew ? "New" : "",
              isExternalLink: globalMenuConfig.educationHub.isExternal
            }
          ]
        : [
            {
              qaLabel: "account-nav-tutorials",
              to: globalMenuConfig.tutorials.link,
              onClick: () => {
                navigateTutorialsGtm(globalMenuConfig.tutorials.link);
                AlchemerEvents.selectTutorialsTab();
              },
              linkText: globalMenuConfig.tutorials.label,
              highlightedText: isTutorialsLinkNew ? "New" : "",
              isExternalLink: globalMenuConfig.tutorials.isExternal
            }
          ]),
      {
        qaLabel: "account-nav-privacyPolicy",
        to: globalMenuConfig.privacyPolicy.link,
        onClick: (e: Event) => {
          onExternalLinkCallback(globalMenuConfig.privacyPolicy?.url || "", e);
          navigationCallBack(
            globalMenuConfig.privacyPolicy?.url || "",
            globalMenuConfig.privacyPolicy.label
          );
        },
        linkText: globalMenuConfig.privacyPolicy.label,
        isExternalLink: globalMenuConfig.privacyPolicy.isExternal
      },
      {
        qaLabel: "account-nav-californiaPolicy",
        to: globalMenuConfig.californiaPolicy.link,
        onClick: (e: Event) => {
          onExternalLinkCallback(
            globalMenuConfig.californiaPolicy?.url || "",
            e
          );
          navigationCallBack(
            globalMenuConfig.californiaPolicy?.url || "",
            globalMenuConfig.californiaPolicy.label
          );
        },
        linkText: globalMenuConfig.californiaPolicy.label,
        isExternalLink: globalMenuConfig.californiaPolicy.isExternal
      },
      ...(doNotSell.toggle
        ? [
            {
              qaLabel: "account-do-not-sell",
              to: globalMenuConfig.doNotSell.link,
              onClick: (e: Event) => {
                onExternalLinkCallback(
                  globalMenuConfig.doNotSell?.url || "",
                  e
                );
                navigationCallBack(
                  globalMenuConfig.doNotSell?.url || "",
                  globalMenuConfig.doNotSell.label
                );
              },
              linkText: globalMenuConfig.doNotSell.label,
              isExternalLink: globalMenuConfig.doNotSell.isExternal
            }
          ]
        : []),
      {
        qaLabel: "account-nav-accessibility",
        to: globalMenuConfig.accessibility.link,
        onClick: () =>
          contentNavigationCallBack(globalMenuConfig.accessibility.label),
        linkText: globalMenuConfig.accessibility.label,
        isExternalLink: globalMenuConfig.accessibility.isExternal
      },
      {
        qaLabel: "account-nav-TermsConditions",
        to: globalMenuConfig.termsAndConidtions?.link,
        onClick: () => {
          const onClickHandler = () => {
            presentUMModal(UMPages.TERMS_AND_CONDITIONS);
            navigationCallBack(
              globalMenuConfig.termsAndConidtions?.url || "",
              globalMenuConfig.termsAndConidtions.label
            );
          };

          if (!isLogged) {
            dispatch(setShowStateSelector(true));
            setGlobalCallback(onClickHandler);
          } else {
            onClickHandler();
          }
        },
        linkText: globalMenuConfig.termsAndConidtions.label,
        isExternalLink: globalMenuConfig.termsAndConidtions.isExternal
      },
      {
        qaLabel: "account-nav-feedback",
        to: globalMenuConfig.feedback.link,
        onClick: (e: Event) => {
          const onClickHandler = () => {
            if (isXSell()) {
              onExternalLinkCallback(globalMenuConfig.feedback.link, e);
              navigationCallBack(
                globalMenuConfig.feedback.link,
                globalMenuConfig.feedback.label
              );
            } else {
              e.preventDefault();
              trackBetaFeatureFeedback();
              AlchemerEvents.showSendFeedbackSurvey();
            }
          };
          handleLinkNeedingPriorLogin(
            noLoginRequiredForAccountPage,
            isLogged,
            onClickHandler
          );
        },
        linkText: globalMenuConfig.feedback.label,
        isExternalLink: isXSell()
      },
      {
        qaLabel: "account-nav-responsibleGaming",
        to: globalMenuConfig.responsibleGaming.link,
        onClick: () => {
          const onClickHandler = () => {
            presentUMModal(UMPages.RESPONSIBLE_PLAY);
          };
          handleLinkNeedingPriorLogin(
            noLoginRequiredForAccountPage,
            isLogged,
            onClickHandler
          );
        },
        linkText: globalMenuConfig.responsibleGaming.label,
        isExternalLink: globalMenuConfig.responsibleGaming.isExternal
      }
    ],
    [
      derbyGuide.toggle,
      isTutorialsLinkNew,
      termsConditions?.url,
      doNotSell.url,
      doNotSell.toggle,
      talentPicks.toggle,
      globalMenuConfig
    ]
  );

  return {
    accountLinks,
    accountOverviewLinks,
    otherLinks
  };
}

export default useGwMenuRoutes;
