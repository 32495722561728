import { UMEventsWallet } from "../../mediator/types";
import { getUMEvent } from "../../mediator/events";
import mediator from "../../mediator";

export const openWalletScreen = (screen: UMEventsWallet) => {
  const event = getUMEvent(screen);
  mediator.dispatch({
    type: event.trigger
  });
};
