import { gql } from "@apollo/client";

const PastRaceResultsFragment = {
  entry: gql`
    fragment runnerDetails on PastResultRunner {
      biNumber
      betAmount
      runnerNumber
      finishPosition
      runnerName
      winPayoff
      placePayoff
      showPayoff
      favorite
    }

    fragment resultsFragment on PastRace {
      results {
        allRunners {
          ...runnerDetails
        }
        runners {
          ...runnerDetails
        }
        payoffs {
          wagerAmount
          selections {
            payoutAmount
            selection
          }
          wagerType {
            code
            name
          }
        }
      }
    }
  `
};

export default PastRaceResultsFragment;
