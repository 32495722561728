import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import tvgConf from "@tvg/conf";

import { setModuleInitializedAction } from "../../../../redux/actions";
import { isUMInitialized } from "../../../../redux/selector";
import { MappedModules, UnifiedModules } from "../../types";
import { useLoadModule } from "../..";

export const useUMInstanceInitialized = <T extends UnifiedModules>(
  unifiedModule: T,
  skipInitialize = false,
  ignoreInitialize = false
) => {
  const dispatch = useDispatch();
  const isInitialized = useSelector(isUMInitialized(unifiedModule));

  const [resolvedModule, setResolvedModule] = useState<MappedModules[T] | null>(
    null
  );
  const module = useLoadModule(unifiedModule, skipInitialize);

  const initializeModule = useCallback(async () => {
    if (!module) {
      const env = tvgConf().environment;
      if (env !== "production") {
        console.log(
          `[UM] useUMInstanceInitialized - Instance of ${unifiedModule} is loading`
        );
      }
    } else if (isInitialized || ignoreInitialize) {
      setResolvedModule(module);
    } else {
      const initialize =
        // @ts-ignore -> Ignoring because we are creating a validation of initialize and this TS Error is saying maybe initialize can't exist
        typeof module?.initialize === "function" && module?.initialize;

      if (initialize) {
        try {
          await initialize();
          setResolvedModule(module);
          setTimeout(() => {
            dispatch(setModuleInitializedAction(unifiedModule));
          }, 100); // register the modules.
        } catch (e) {
          console.error(`[UM] useUMInstanceInitialized - Error: ${e}`);
        }
      } else {
        setResolvedModule(module);
      }
    }
  }, [module, isInitialized]);

  useEffect(() => {
    if (!resolvedModule) {
      initializeModule();
    }
  }, [module, isInitialized]);

  return resolvedModule;
};
