import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import mediator from "@tvg/mediator";
import { isMobile, isXSell } from "@tvg/sh-utils/mobileUtils";
import type { Unsubscribe } from "@fanduel/account-contract";
import { getCoreConfigState } from "../../../../../../redux/selector";
import { AccountModule } from "../../../../../useLoadModule/types";
import { webSessionUpdateSubscribe } from "./web/sessionUpdate";
import { visibilityHandler } from "./hybrid/visibilityHandler";

export const useCoreConfigSubscription = (account: AccountModule | null) => {
  const coreConfigState = useSelector(getCoreConfigState);
  const [webUnsubscribe, setWebUnsubscribe] = useState<
    Unsubscribe | undefined
  >();
  const dispatch = useDispatch();

  useEffect(() => {
    const isNeedListenerVisibility = isMobile() && !isXSell();

    if (isNeedListenerVisibility) {
      document.addEventListener("visibilitychange", visibilityHandler);
    }

    return () => {
      if (isNeedListenerVisibility) {
        document.removeEventListener("visibilitychange", visibilityHandler);
      }
    };
  }, []);

  useEffect(() => {
    if (isMobile() && coreConfigState) {
      mediator.ios.dispatch({
        type: "CORE_CONFIG_STATE_CHANGED",
        payload: {
          state: coreConfigState
        }
      });
    } else if (account) {
      if (typeof webUnsubscribe === "function") {
        webUnsubscribe();
      }

      webSessionUpdateSubscribe(account, coreConfigState, dispatch).then(
        setWebUnsubscribe
      );
    }

    return () => {
      if (typeof webUnsubscribe === "function") {
        webUnsubscribe();
      }
    };
  }, [coreConfigState, account]);
};
