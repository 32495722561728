import React from "react";
import { Icon } from "../../../Icon";
import { BetTypesButton, BetTypesText } from "./styled-components";
import type { BetTypeButtonProps } from "./types";

export const BetTypeButton: React.FC<BetTypeButtonProps> = ({
  betType,
  isSelected = false,
  hasPromo = false,
  hasPromoOptedIn = false,
  qaLabel = betType.code,
  onPress
}) => {
  const onClick = () => {
    onPress(betType.id);
  };

  return (
    <BetTypesButton
      onClick={onClick}
      isSelected={isSelected}
      data-qa-label={`${qaLabel}${isSelected ? "-active" : ""}`}
    >
      {hasPromo && (
        <Icon
          pr="space-1"
          qaLabel="bet-type-icon-promo"
          name="promo"
          size="s"
          lineColor={
            hasPromoOptedIn
              ? "--fd-colors-system-positive-content-accent"
              : "--fd-colors-system-alert-content-accent"
          }
          backgroundColor={
            hasPromoOptedIn
              ? "--fd-colors-system-positive-background-subtle"
              : "--fd-colors-system-alert-background-subtle"
          }
        />
      )}
      <BetTypesText isSelected={isSelected}>{betType.name}</BetTypesText>
    </BetTypesButton>
  );
};
