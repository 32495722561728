import { useEffect, useState } from "react";
import { isMobile } from "@tvg/sh-utils/mobileUtils";
import { useDispatch } from "react-redux";
import { AccountModule, WalletModule } from "../../../useLoadModule/types";
import { webWalletSubscriptions } from "./utils/web";
import { walletSubscriptions } from "./utils/completion";

export const useSubscriptions = (
  walletInstance: WalletModule | null,
  accountInstance: AccountModule | null
) => {
  const [isSubscribing, setIsSubscribing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isSubscribing) {
      if (isMobile()) {
        setIsSubscribing(true);

        walletSubscriptions(dispatch);
      } else if (walletInstance && accountInstance) {
        setIsSubscribing(true);

        walletSubscriptions(dispatch);
        webWalletSubscriptions(walletInstance, accountInstance);
      }
    }
  }, [walletInstance, isSubscribing, accountInstance]);
};
