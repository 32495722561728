import { get } from "lodash";
import type { Config, Environment } from "@fanduel/core-config-contract";
import { getCookieValue } from "@tvg/sh-utils/cookieUtils";
import { Product } from "@fanduel/core-config-contract/dist/appDomain";
import TVGConf from "@tvg/conf";
import type { TVGEnvironment } from "../types/environment";
import environmentMap from "../contraints/environmentMap";

const getDevStack = () => getCookieValue("devstack-name", "", false);

const getEnvironment = (environment: TVGEnvironment): Environment => {
  let type = get(environmentMap, environment, "prod");
  let devStack = "";

  if (environment !== "production") {
    const devstackName = getDevStack();

    if (devstackName) {
      type = "devStack";
      devStack = devstackName;
    }
  }

  return {
    type,
    name: devStack
  } as Environment;
};

export const getConfig = (
  environment: TVGEnvironment,
  region?: string
): Config => ({
  clientAuthToken: TVGConf().config().clientId,
  environment: getEnvironment(environment),
  appDomain: {
    product: Product.Racing,
    region
  }
});
