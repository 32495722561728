import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import { getGeolocationError } from "@tvg/sh-geolocation/src/redux/selectors";
import {
  getIsLogged,
  getIsTermsAccepted,
  getIsVerified
} from "@urp/store-selectors";
import { isMobile, isXSell } from "@tvg/sh-utils/mobileUtils";
import { parseJSONCapiMessage } from "@fdr/utils/parseJSONCapiMessage";

import { AccountModule } from "../../../useLoadModule/types";
import {
  getCoreConfigState,
  getIsModalOpened
} from "../../../../redux/selector";
import { webAccountSubscriptions } from "./utils/web";
import { accountSubscriptions } from "./utils/completion";
import { ExtraProps } from "./utils/completion/types";
import { useUserLocationSubscription } from "./hooks/useUserLocationSubscription";
import { useVariablesRefs } from "../../../utils/useVariablesRefs";
import { xsellAccountSubscriptions } from "./utils/xsell";
import { sharedSubscriptions } from "./utils/shared";
import { firstLoad } from "../../../../utils/account/firstLoad";
import { hotReloadSubscription } from "./utils/completion/hotReload";
import { useCoreConfigSubscription } from "./hooks/useCoreConfigSubscription";

export const useSubscriptions = (account: AccountModule | null) => {
  const [isSubscribing, setIsSubscribing] = useState(false);
  const dispatch = useDispatch();
  const coreConfigRegion = useSelector(getCoreConfigState);
  const history = useNavigate();
  const location = useLocation();
  const geolocationError = useSelector(getGeolocationError);
  const userIsVerified = useSelector(getIsVerified);
  const userTermsAndCondition = useSelector(getIsTermsAccepted);
  const geolocation = useSelector((store) => get(store, "geolocation"));
  const isLogged = useSelector(getIsLogged);
  const allowedStates = useSelector((store) =>
    Object.keys(
      parseJSONCapiMessage(store, "capi.messages.stateSelectorListFdr", {})
    )
  );
  const userFavoriteTracksLoaded = useSelector((store) =>
    get(store, "userFavorites.tracksLoaded", false)
  );
  const enableCPPPromos = useSelector((store) =>
    get(store, "capi.featureToggles.enableCPPPromos", false)
  );
  const coreConfigState = useSelector(getCoreConfigState);
  const isLoginOpened = useSelector(getIsModalOpened);

  const customProps = useVariablesRefs<ExtraProps>({
    region: coreConfigRegion,
    geolocationError,
    isVerified: userIsVerified,
    isTermsAccepted: userTermsAndCondition,
    geolocation,
    allowedStates,
    isLogged,
    userFavoriteTracksLoaded,
    enableCPPPromos,
    coreConfigState
  });

  useUserLocationSubscription();
  useCoreConfigSubscription(account);

  useEffect(() => {
    if (!isSubscribing) {
      if (isMobile()) {
        setIsSubscribing(true);
        accountSubscriptions(dispatch, history, location, customProps);
        if (isXSell()) {
          xsellAccountSubscriptions(dispatch, customProps);
        } else {
          hotReloadSubscription(customProps);
        }
        sharedSubscriptions(customProps);
        firstLoad();
      } else if (account) {
        setIsSubscribing(true);
        webAccountSubscriptions(account, customProps, dispatch);
        accountSubscriptions(dispatch, history, location, customProps);
        sharedSubscriptions(customProps);
      }
    }
  }, [account, isSubscribing]);

  // reload page if session is invalid to close login modal
  useEffect(() => {
    if (account && isLoginOpened && isLogged) {
      window.location.reload();
    }
  }, [account, isLoginOpened, isLogged]);

  return isSubscribing;
};
