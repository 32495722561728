import type { Session, User } from "@fanduel/account-contract";
import { get } from "lodash";
import type { UserUpdatePayload } from "./types";
import { getUMEvent } from "../../../../../../../../utils/mediator/events";
import { UMEvents } from "../../../../../../../../utils/mediator/types";
import { getBalance } from "../../../../../../../../utils/wallet/getBalance";
import mediator from "../../../../../../../../utils/mediator";

export const updateUserDataCallback = (data: UserUpdatePayload) => {
  const user = get(data, "payload.user");
  const session = get(data, "payload.session");

  updateUserData(user, session);
};

export const updateUserData = (user: User, session: Session) => {
  const modalPresenterEvent = getUMEvent(UMEvents.PresentModal);

  getBalance();
  mediator.dispatch({
    type: modalPresenterEvent.completion,
    payload: {
      user,
      session
    }
  });
};
