import { Dispatch } from "redux";
import { getBalanceSubscription } from "./getBalance";
import { walletScreensSubscription } from "../screensMapping/screensSubscription";

export const walletSubscriptions = (dispatch: Dispatch) => {
  getBalanceSubscription(dispatch);

  // This subscription have all wallet screens,
  // if needed override please remove from screen mapping and create a new subscription function
  // also remember to change the web subscriptions
  walletScreensSubscription();
};
