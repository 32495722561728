import { useEffect, useState } from "react";
import { isMobile } from "@tvg/sh-utils/mobileUtils";
import type { AmplitudeModule } from "../../../useLoadModule/types";
import { webAmplitudeSubscriptions } from "./utils/web";

export const useSubscriptions = (amplitude: AmplitudeModule | null) => {
  const [isSubscribing, setIsSubscribing] = useState(false);

  useEffect(() => {
    if (!isSubscribing) {
      if (!isMobile() && amplitude) {
        webAmplitudeSubscriptions(amplitude);
        setIsSubscribing(true);
      }
    }
  }, [amplitude, isSubscribing]);

  return isSubscribing;
};
