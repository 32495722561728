import { useEffect, useState } from "react";
import { noop } from "lodash";
import { useSelector } from "react-redux";
import { getIsLogged } from "@urp/store-selectors";
import { keepAliveSessionCallback } from "../utils/keepAliveCallback";

const CLICK_EVENTS = ["touchstart", "click"];

const cleanEvents = (cb: EventListenerOrEventListenerObject | null) => {
  if (typeof cb === "function") {
    CLICK_EVENTS.forEach((eventType) => {
      document.removeEventListener(eventType, cb);
    });
  }
};

export const useKeepUserSessionAliveByClick = () => {
  const isLogged = useSelector(getIsLogged);
  const [storageCb, setStorageCb] =
    useState<EventListenerOrEventListenerObject | null>(null);

  useEffect(() => {
    if (typeof window === "undefined") return noop;

    const cb: EventListenerOrEventListenerObject = () => {
      keepAliveSessionCallback(isLogged);
    };

    if (storageCb !== cb) {
      cleanEvents(storageCb);
      setStorageCb(cb);

      CLICK_EVENTS.forEach((eventType) => {
        document.addEventListener(eventType, cb);
      });
    }

    return () => {
      cleanEvents(storageCb);
    };
  }, [isLogged]);
};
