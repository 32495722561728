import { breakpoints } from "@tvg/design-system/src";
import styled, { css, keyframes } from "styled-components";
import { rgba } from "polished";

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    display: none;
  }
`;

export const Container = styled.div<{ isDismissed: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 8px;
  z-index: 2;
  position: relative;
  margin: 4px 0;
  background-color: var(--fd-colors-background-promotional, #011638);
  border-radius: 4px;

  ${({ isDismissed }) =>
    isDismissed &&
    css`
      animation: ${fadeOut} 0.5s linear forwards;
    `}

  @media screen and ${breakpoints.tablet.max.sm} {
    border-radius: 0;
    margin: 0;
    background-color: var(--fd-colors-background-promotional, #011638);
    z-index: 1;
    gap: 6px;
  }

  @media screen and ${breakpoints.desktop.max.md} {
    width: 100dvw;
    border-radius: 0;
  }

  @media screen and (max-width: 390px) {
    justify-content: flex-start;
    padding-left: var(--fd-space-space-3);
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
`;

export const IconExperiment = styled.div`
  margin-right: -13px;
`;

export const CloseContainer = styled.div`
  position: absolute;
  right: 8px;

  @media screen and ${breakpoints.tablet.max.sm} {
    top: 7px;
    right: 0;
  }

  @media screen and ${breakpoints.desktop.max.md} {
    right: 16px;
  }

  @media screen and (max-width: 390px) {
    right: 2px;
  }
`;

export const TemplatesContainer = styled.div`
  pointer-events: auto;
  display: flex;
  flex-flow: column nowrap;
  z-index: 1;

  @media screen and ${breakpoints.tablet.min.sm} {
    position: absolute;
    padding: var(--fd-space-space-3);
    background-color: var(--fd-colors-core-white);
    width: 375px;
    top: 36px;
    right: 50%;
    transform: translateX(50%);
    border: 1px solid var(--fd-colors-border-default);
    border-radius: var(--fd-space-space-1);
    box-shadow: 0 0 16px 2px rgb(10 10 10 / 30%);
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -12px;
    right: -12px;
    height: 1px;
    background-color: var(--fd-colors-border-subtle);
  }
`;

export const CloseModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) =>
    rgba(theme.colorTokens.background.secondary, 0.57)};
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
`;
