import { get } from "lodash";
import { decodeToken } from "@tvg/sh-utils/sessionUtils";
import type { Session } from "@fanduel/account-contract";

export const formatSession = (token: string | undefined): Session | null => {
  if (!token) {
    return null;
  }
  const tokenDecoded = decodeToken(token);

  return {
    token,
    sessionId: get(tokenDecoded, "ses", ""),
    created: get(tokenDecoded, "crt", ""),
    expires: get(tokenDecoded, "exp", "")
  };
};
