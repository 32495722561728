interface SetUserFavoriteTracks {
  type: "SET_USER_FAVORITE_TRACKS";
  payload: {
    trackCode?: string;
    favoriteTracks: string[];
  };
}
export const setUserFavoriteTracks = (
  favoriteTracks: string[]
): SetUserFavoriteTracks => ({
  type: "SET_USER_FAVORITE_TRACKS",
  payload: { favoriteTracks }
});
