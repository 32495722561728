import { useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useIsomorphicLayoutEffect, usePrevious } from "@tvg/custom-hooks";
// @ts-ignore
import { getIsLoginModalOpen } from "@tvg/sh-lib-account-actions/src/selectors/modal";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { getIsLogged } from "@urp/store-selectors";
import hashInterceptor from "../utils/hashInterceptor";

const useHashInterceptor = () => {
  const currentLocation = useLocation();
  const previousLocation = usePrevious(currentLocation);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLogged = useSelector(getIsLogged);
  const isAccountCompliant = useSelector(isAccountCompliantSelector);
  const isLoginModalOpen: boolean = useSelector(getIsLoginModalOpen);

  useIsomorphicLayoutEffect(() => {
    hashInterceptor(
      previousLocation,
      currentLocation,
      dispatch,
      navigate,
      isLogged,
      isLoginModalOpen,
      isAccountCompliant
    );
  }, [
    currentLocation.key,
    currentLocation.pathname,
    currentLocation.hash,
    currentLocation.search,
    isLogged,
    isLoginModalOpen,
    isAccountCompliant
  ]);
};

export default useHashInterceptor;
