import { Dispatch } from "redux";
import { get } from "lodash";
import type { Balance } from "@fanduel/wallet-contract";

import { getUMEvent } from "../../../../../../../utils/mediator/events";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import mediator from "../../../../../../../utils/mediator";
import { BalancePayload } from "./types";
import { setRacingWallet } from "./setRacingWallet";

export const getBalanceSubscription = (dispatch: Dispatch) => {
  const event = getUMEvent(UMEvents.GetBalance);

  mediator.subscribe(event.completion, (data: BalancePayload) => {
    const balances: Set<Balance> = get(data, "payload.balances");
    const accountNumber = get(data, "payload.gtm.accountNumber");
    setRacingWallet(balances, dispatch, accountNumber);
  });
};
