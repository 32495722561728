import { URPEducationHubMessages } from "@urp/education-hub/src/types";
import { SubMenus } from "./types";

export type ParseOptions = {
  isURPEducationHubEnable?: boolean;
  urpEducationHubMessages?: URPEducationHubMessages;
  isDropdownMenu?: boolean;
};

export type UpdaterOptions = {
  isURPEducationHubEnable?: boolean;
  urpEducationHubMessages?: URPEducationHubMessages;
};

// Change a menu route (which is a CAPI message) based on a feature toogle
// We need validate whether we can have a better approach
export const parseSubMenus = (subMenus: SubMenus[], options: ParseOptions) => {
  const { isURPEducationHubEnable, urpEducationHubMessages } = options;

  return subMenus.reduce((acc: SubMenus[], item) => {
    if (
      isURPEducationHubEnable &&
      urpEducationHubMessages &&
      item.route.startsWith("/promos/horse-racing-101")
    ) {
      acc.push(urpEducationHubMessages.homeMenuItem);
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
};

export const updateRouteByToggle = (route: string, options: ParseOptions) => {
  const { isURPEducationHubEnable, urpEducationHubMessages } = options;

  if (
    route.startsWith("/promos/horse-racing-101") &&
    isURPEducationHubEnable &&
    urpEducationHubMessages?.homeMenuItem.route
  ) {
    return urpEducationHubMessages?.homeMenuItem.route;
  }
  return route;
};
