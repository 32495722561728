import { useCallback, useEffect, useState } from "react";
import { get } from "lodash";
import modulesLoader from "./modulesLoader";
import type { LazyModuleLoader, MappedModules, UnifiedModules } from "./types";

export const useLoadModule = <T extends UnifiedModules>(
  module: T,
  ignoreImport = false
): MappedModules[T] | null => {
  const [moduleLoaded, setModuleLoaded] = useState<MappedModules[T] | null>(
    null
  );

  const startLoadModule = useCallback(async () => {
    if (ignoreImport || moduleLoaded) {
      return;
    }

    const moduleLoader = get(modulesLoader, module) as LazyModuleLoader<
      MappedModules[T]
    >;

    if (typeof moduleLoader !== "function") {
      console.error(`[UM] useLoadModule - Error: Can't find module ${module}`);
      return;
    }

    try {
      setModuleLoaded(await moduleLoader());
    } catch (e) {
      console.error(
        `[UM] useLoadModule - Error: trying load the library: ${e}`
      );
    }
  }, [moduleLoaded, ignoreImport]);

  useEffect(() => {
    startLoadModule();
  }, [ignoreImport]);

  return moduleLoaded;
};
