import type { MutableRefObject } from "react";
import { get } from "lodash";
import mediator from "@tvg/mediator";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import mediatorScoped from "../../../../../../../utils/mediator";
import type { ExtraProps } from "./types";

export const sharedComplianceSubscription = (
  extraProps: MutableRefObject<Partial<ExtraProps>>
) => {
  const eventVerification = getUMEvent(UMEvents.Verification);
  const eventAcceptTerms = getUMEvent(UMEvents.AcceptTerms);

  mediator.base.subscribe("UM_ACCOUNT_COMPLIANCE", () => {
    const accepted = get(extraProps, "current.accepted", false);
    const isVerified = get(extraProps, "current.isVerified", false);

    if (!isVerified) {
      mediatorScoped.dispatch({
        type: eventVerification.trigger,
        payload: {
          isVerified,
          showUI: !isVerified
        }
      });
    }

    if (isVerified && !accepted) {
      mediatorScoped.dispatch({
        type: eventAcceptTerms.trigger,
        payload: {
          accepted,
          showUI: isVerified && !accepted
        }
      });
    }
  });
};
