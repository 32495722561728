import mediator from "../../mediator";
import { UMEvents } from "../../mediator/types";
import { getUMEvent } from "../../mediator/events";

export const keepSessionAlive = () => {
  const event = getUMEvent(UMEvents.KeepAlive);

  mediator.dispatch({
    type: event.trigger
  });
};
