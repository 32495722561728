export enum UMPages {
  ACCOUNT = "account",
  SETTINGS = "settings",
  TRANSACTIONS = "transactions",
  TAX_INFORMATION = "taxInformation",
  DEPOSIT = "deposit",
  WITHDRAWALS = "withdrawals",
  RESPONSIBLE_PLAY = "responsiblePlay",
  VERIFICATION = "verification",
  ACCEPT_TERMS = "AcceptTerms",
  ACTIVITY_STATEMENT = "activityStatement",
  TAX_CENTER = "taxCenter",
  TERMS_AND_CONDITIONS = "termsAndConditions",
  MY_SPEND = "mySpend"
}
