import { debounce } from "lodash";
import { keepSessionAlive } from "../../../../../../utils/account/keepAlive";

const DEBOUNCE_TIME = 1000; // 1s

const keepAlive = debounce((isLogged: boolean) => {
  if (!isLogged) {
    return;
  }

  keepSessionAlive();
}, DEBOUNCE_TIME);

export const keepAliveSessionCallback = (isLogged: boolean) => {
  keepAlive(isLogged);
};
