import { useEffect } from "react";
import { useDispatch } from "react-redux";
import mediator from "@tvg/mediator";

import { setLocationBehindScenesAction } from "@urp/location-splash/src/actions";

export const useUserLocationSubscription = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    mediator.base.subscribe("GET_USER_REGION_BY_LOGIN", () => {
      dispatch(setLocationBehindScenesAction(true));
    });
  }, []);
};
