import type { MutableRefObject } from "react";
import type { Dispatch } from "redux";
import { get } from "lodash";
import type { SessionHint } from "@fanduel/account-contract";

import type { AccountModule } from "../../../../../../useLoadModule/types";
import type { ExtraProps, OpenAuthenticationPayload } from "./types";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import mediatorScoped from "../../../../../../../utils/mediator";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import { setAccountModalIsOpened } from "../../../../../../../redux/actions";

export const webOpenAuthenticationSubscription = (
  accountInstance: AccountModule,
  extraProps: MutableRefObject<Partial<ExtraProps>>,
  dispatch: Dispatch
) => {
  const event = getUMEvent(UMEvents.Session);

  mediatorScoped.subscribe(
    event.trigger,
    async (data: OpenAuthenticationPayload) => {
      const region: string = get(extraProps, "current.region");
      const isLogged: string = get(extraProps, "current.isLogged");
      const coreConfigState: string = get(
        extraProps,
        "current.coreConfigState"
      );
      const hint: SessionHint = get(data, "payload.hint", "Normal");
      const error: string = get(data, "payload.error");

      if (!coreConfigState || (isLogged === undefined && hint !== "Silent")) {
        return;
      }

      dispatch(setAccountModalIsOpened(true));
      const session = await accountInstance.getSessionAsync(hint);

      dispatch(setAccountModalIsOpened(false));

      const user = await accountInstance.getUserAsync("Silent");

      const enforceAcceptTerms = get(extraProps, "current.enforceAcceptTerms");

      mediatorScoped.dispatch({
        type: event.completion,
        payload: {
          user,
          session,
          region,
          enforceAcceptTerms,
          error
        }
      });
    }
  );
};
