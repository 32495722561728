import { isMobile } from "@tvg/sh-utils/mobileUtils";
import { useUMInstanceInitialized } from "../useLoadModule/hooks/useInitializeModule";
import { UnifiedModules } from "../useLoadModule/types";
import { useSubscriptions } from "./hooks/useSubscriptions";

export const useAmplitude = () => {
  const isMobileDevice = isMobile();
  const amplitudeInstance = useUMInstanceInitialized(
    UnifiedModules.AMPLITUDE,
    isMobileDevice
  );

  useSubscriptions(amplitudeInstance);
};
