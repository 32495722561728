import { NullaryFn } from "@tvg/ts-types/Functional";
import { get } from "lodash";
import { UMPages } from "../presentUMModal/types";
import { openUserHomepage } from "../../account/openUserHomepage";
import { openWalletScreen } from "../../wallet/openScreen";
import { UMEventsWallet } from "../../mediator/types";
import { presentUMModal } from "../presentUMModal";

const UMPagesMap: Record<string, NullaryFn<void>> = {
  "responsible-gaming": () => presentUMModal(UMPages.RESPONSIBLE_PLAY),
  "my-profile": openUserHomepage,
  "my-account-summary": () => openWalletScreen(UMEventsWallet.Transactions),
  "tax-information": () => openWalletScreen(UMEventsWallet.Tax),
  "account-summary": () => openWalletScreen(UMEventsWallet.Transactions),
  "activity-statement": () => openWalletScreen(UMEventsWallet.Activity),
  "account-balance": () => openWalletScreen(UMEventsWallet.AccountHome),
  "tax-center": () => openWalletScreen(UMEventsWallet.Tax),
  "my-spend": () => presentUMModal(UMPages.MY_SPEND)
};

export const checkIsValidPageByQaLabel = (qaLabel: string) =>
  Object.keys(UMPagesMap).includes(qaLabel);

export const presentModalByQaLabel = (qaLabel: string) => {
  const showScreen = get(UMPagesMap, qaLabel);
  if (typeof showScreen === "function") {
    showScreen();
  }
};
