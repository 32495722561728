import React from "react";
import { Button } from "@tvg/design-system/web";
import { TemplateListContainer, ButtonsWrapper } from "./styled-components";
import {
  SectionConfigType,
  HomepageStoryblokConfig,
  HomepageStoryblokMobileConfig
} from "../../../types";
import TemplateItem from "../../TemplateOption";

interface TemplateListProps {
  templatesConfig: HomepageStoryblokConfig | HomepageStoryblokMobileConfig;
  selectedTemplate: string;
  getTemplateText: (
    templatesConfig: HomepageStoryblokMobileConfig | HomepageStoryblokConfig,
    template: string,
    value: SectionConfigType
  ) => string;
  onTemplateSelect: (template: string) => void | Promise<void>;
  onApply: () => void;
  isApplyDisabled: boolean;
}

export const TemplateList: React.FC<TemplateListProps> = ({
  templatesConfig,
  selectedTemplate,
  getTemplateText,
  onTemplateSelect,
  onApply,
  isApplyDisabled
}) => (
  <TemplateListContainer data-qa-label="hp-template-selector-list">
    {templatesConfig.templates.map((template: string) => (
      <TemplateItem
        key={template}
        qaLabel={`hp-template-selector-option-${template}`}
        title={getTemplateText(
          templatesConfig,
          template,
          SectionConfigType.LABEL_NAME
        )}
        description={getTemplateText(
          templatesConfig,
          template,
          SectionConfigType.DESCRIPTION
        )}
        onClick={() => onTemplateSelect(template)}
        isActive={selectedTemplate === template}
      />
    ))}
    <ButtonsWrapper>
      <Button
        variant="primary"
        onClick={onApply}
        qaLabel="apply-button"
        size="m"
        isDisabled={isApplyDisabled}
        isStretched
        padding="var(--fd-space-space-5)"
      >
        Apply
      </Button>
    </ButtonsWrapper>
  </TemplateListContainer>
);

export default TemplateList;
