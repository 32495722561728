import { get } from "lodash";
import { decodeToken } from "@tvg/sh-utils/sessionUtils";
import type { User } from "@fanduel/account-contract";

export const formatUser = (token: string | undefined): User | null => {
  if (!token) {
    return null;
  }
  const tokenDecoded = decodeToken(token);

  return {
    userId: get(tokenDecoded, "sub", ""),
    username: get(tokenDecoded, "usn", ""),
    email: get(tokenDecoded, "eml", ""),
    mfa: get(tokenDecoded, "mfa", ""),
    hasDeposited: false
  } as unknown as User; // Is a partial user
};
