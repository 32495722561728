import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";

import { PastRaceResults } from "@tvg/ts-types/Race";
import { getWagerProfile } from "@urp/store-selectors";
import { TRACKS_FILTER_OPTIONS, TRACKS_POLLER_INTERVAL } from "../constants";
import { GET_PAST_RACES_QUERY } from "../graphql/queries/PastRacesQuery";
import { filterAndSortRunners } from "../utils/races";

const usePastRaces = ({
  date,
  trackCode,
  raceNumber,
  runnerId,
  enabled = true,
  polling = true,
  pollInterval = TRACKS_POLLER_INTERVAL
}: {
  date?: string;
  trackCode?: string;
  raceNumber?: string;
  runnerName?: string;
  runnerId?: string;
  enabled?: boolean;
  polling?: boolean;
  pollInterval?: number;
}) => {
  const [pastRaces, setPastRaces] = useState<Array<PastRaceResults>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const byDate = !!date && !trackCode && !raceNumber;
  const byDateTrack = !!date && !!trackCode && !raceNumber;
  const byDateTrackNumber = !!date && !!trackCode && !!raceNumber;
  const byRunnerId = !!runnerId;

  const wagerProfile = useSelector(getWagerProfile);

  const getQueryVariables = useCallback(
    () => ({
      wagerProfile,
      filterBy: {},
      byDate,
      byDateTrack,
      date,
      trackCode,
      byDateTrackNumber,
      raceNumber,
      byRunnerId,
      runnerId
    }),
    [
      wagerProfile,
      byDate,
      byDateTrack,
      date,
      trackCode,
      byDateTrackNumber,
      raceNumber,
      byRunnerId,
      runnerId
    ]
  );

  const shouldSkip =
    !enabled ||
    !(
      (date &&
        trackCode &&
        trackCode !== TRACKS_FILTER_OPTIONS.ALL_TRACKS.value) ||
      runnerId
    );

  const { loading, data, refetch } = useQuery(GET_PAST_RACES_QUERY, {
    variables: getQueryVariables(),
    skip: shouldSkip,
    fetchPolicy: "cache-and-network",
    pollInterval: shouldSkip || !polling ? 0 : pollInterval
  });

  useEffect(() => {
    if (!shouldSkip) {
      refetch(getQueryVariables());
    } else {
      setPastRaces([]);
    }
  }, [
    date,
    trackCode,
    raceNumber,
    runnerId,
    enabled,
    refetch,
    getQueryVariables,
    shouldSkip
  ]);

  useEffect(() => {
    if (!loading && data) {
      let races: Array<PastRaceResults> = [];
      if (byDate || byDateTrack || byDateTrackNumber || byRunnerId) {
        if (byDate) {
          races = data.pastRacesByDate;
        }
        if (byDateTrack) {
          races = filterAndSortRunners(data.pastRacesByDateAndTrack);
        }
        if (byDateTrackNumber) {
          races = data.pastRaceByDateTrackAndNumber;
        }
        if (byRunnerId) {
          races = filterAndSortRunners(data.pastRacesByHorseId);
        }
      }

      setPastRaces(races);
    }
  }, [loading, data]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return { pastRaces, isLoading, firstPastRace: pastRaces[0] };
};

export default usePastRaces;
