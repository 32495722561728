import type { LazyMappedModule } from "./types";

const mappedModulesLoader: LazyMappedModule = {
  CoreConfig: () => import("@fanduel/core-config").then((m) => m.CoreConfig),
  CorePx: () => import("@fanduel/core-px").then((m) => m.CorePx),
  CoreApiIdentities: () =>
    import("@fanduel/core-api-identities").then((m) => m.CoreApiIdentities),
  Account: () => import("@fanduel/account").then((m) => m.Account),
  ModalPresenter: () =>
    import("@fanduel/core-modal-presenter").then((m) => m.ModalPresenter),
  Wallet: () => import("@fanduel/wallet").then((m) => m.Wallet),
  CoreWebView: () => import("@fanduel/core-webview").then((m) => m.default),
  Amplitude: () => import("@fanduel/amplitude-um").then((m) => m.AmplitudeUM),
  CoreEvents: () => import("@fanduel/core-events").then((m) => m.default)
};

export default mappedModulesLoader;
