import type { Session, User } from "@fanduel/account-contract";
import type { Dispatch } from "redux";

export enum LoginError {
  GhostAccount,
  Region
}

export interface CompleteLoginProps {
  accountUser: User;
  session: Session;
  dispatch: Dispatch;
  enforceAcceptTerms?: boolean;
  userFavoriteTracksLoaded?: boolean;
  errorCode?: LoginError;
  callback?: Function;
  enableCPPPromos?: boolean;
}
