import { isMobile } from "@tvg/sh-utils/mobileUtils";
import { useSetUserId } from "./hooks/useSetUserId";
import { useUMInstanceInitialized } from "../useLoadModule/hooks/useInitializeModule";
import { UnifiedModules } from "../useLoadModule/types";

export const useCorePx = () => {
  const isMobileDevice = isMobile();
  const corePxInstance = useUMInstanceInitialized(
    UnifiedModules.CORE_PX,
    isMobileDevice
  );
  useSetUserId(corePxInstance);
};
