import React, { Ref, useRef } from "react";
import type { CoreWebViewConfig, ICoreWebView } from "@fanduel/core-webview";
import { UMPages } from "../../../../../../../utils/modalPresenter/presentUMModal/types";
import { CoreWebviewModule } from "../../../../../../useLoadModule/types";

export const ModalWebView = (props: {
  component: CoreWebviewModule;
  source: UMPages;
}) => {
  const coreWebViewRef: Ref<ICoreWebView> = useRef(null);
  const config: CoreWebViewConfig = {
    authMode: {
      id: "silent"
    }
  };
  const Component = props.component;
  return (
    <Component ref={coreWebViewRef} source={props.source} config={config} />
  );
};
