import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getFdUserId } from "@urp/store-selectors/users";
import type { CorePxModule } from "../../useLoadModule/types";

export const useSetUserId = (corePx: CorePxModule | null) => {
  const userId = useSelector(getFdUserId);

  useEffect(() => {
    if (corePx) {
      corePx.setUserId(userId);
    }
  }, [corePx, userId]);
};
