import React from "react";
import {
  useAmplitude,
  useCorePx,
  useCoreConfig,
  useAccount,
  useCoreApiIdentities,
  useCoreModalPresenter,
  useWallet,
  useCoreEvents
} from "../hooks";

const UnifiedModulesWrapper = () => {
  useCoreConfig();
  useCorePx();
  useCoreApiIdentities();
  useAccount();
  useCoreModalPresenter();
  useWallet();
  useAmplitude();
  useCoreEvents();

  return <></>;
};

export default UnifiedModulesWrapper;
