import { isMobile } from "@tvg/sh-utils/mobileUtils";
import { useUMInstanceInitialized } from "../useLoadModule/hooks/useInitializeModule";
import { UnifiedModules } from "../useLoadModule/types";
import { useSubscriptions } from "./hooks/useSubscriptions";

export const useWallet = () => {
  const isMobileDevice = isMobile();
  const walletInstance = useUMInstanceInitialized(
    UnifiedModules.WALLET,
    isMobileDevice
  );
  const accountInstance = useUMInstanceInitialized(
    UnifiedModules.ACCOUNT,
    isMobileDevice,
    true
  );
  useSubscriptions(walletInstance, accountInstance);
};
