import { get } from "lodash";
import type { AmplitudeModule } from "../../../../../../useLoadModule/types";
import type { SetDeviceIdPayload } from "./type";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import mediatorScoped from "../../../../../../../utils/mediator";

export const webSetDeviceIdSubscription = (amplitude: AmplitudeModule) => {
  const event = getUMEvent(UMEvents.SetDeviceId);

  mediatorScoped.subscribe(event.trigger, (data: SetDeviceIdPayload) => {
    const sessionId: string | undefined = get(data, "payload.sessionId");
    amplitude.setDeviceId(sessionId);
  });
};
