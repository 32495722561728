import type { MutableRefObject } from "react";
import type { Dispatch } from "redux";
import mediator from "@tvg/mediator";
import { get } from "lodash";
import type { SessionHint } from "@fanduel/account-contract";
import { clearCallback } from "@tvg/sh-utils/callbackUtils";
import {
  clearAuthTokenCookie,
  clearLoginTokenCookie
} from "@tvg/sh-utils/sessionUtils";

import type { ExtraProps, OpenAuthenticationPayload } from "./types";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import mediatorScoped from "../../../../../../../utils/mediator";
import { completeLogin } from "../../../services/completeLogin";
import { LoginError } from "../../../services/completeLogin/types";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import { getUserLocation } from "../../../hooks/useUserLocationSubscription/actions";
import { formatAuthDetail } from "../../../../../../../utils/account/formatAuthDetail";
import { logout } from "../../../../../../../utils/account/logout";

const skipGeolocationVerify: Array<SessionHint> = ["Silent", "Local"];

export const openAuthenticationSubscription = (
  dispatch: Dispatch,
  extraProps: MutableRefObject<Partial<ExtraProps>>
) => {
  const event = getUMEvent(UMEvents.Session);

  mediatorScoped.subscribe(
    event.completion,
    (data: OpenAuthenticationPayload) => {
      const { user, session } = formatAuthDetail({
        user: get(data, "payload.user"),
        session: get(data, "payload.session"),
        authToken: get(data, "payload.authToken")
      });

      const hint: SessionHint = get(data, "payload.hint");
      const error: LoginError = get(data, "payload.error", null);
      const enforceAcceptTerms: boolean | undefined = get(
        extraProps,
        "current.enforceAcceptTerms"
      );
      const enableCPPPromos: boolean | undefined = get(
        extraProps,
        "current.enableCPPPromos"
      );
      const isLogged: boolean = get(extraProps, "current.isLogged", false);

      if (error === LoginError.Region) {
        if (!skipGeolocationVerify.includes(hint)) {
          mediator.base.dispatch(getUserLocation());
        }
      } else if (!user || !session) {
        clearCallback();

        if (isLogged) {
          logout();
        } else {
          clearAuthTokenCookie();
          clearLoginTokenCookie();
        }
      } else {
        completeLogin({
          accountUser: user,
          session,
          dispatch,
          errorCode: error,
          enforceAcceptTerms,
          enableCPPPromos
        });
      }
    }
  );
};
