import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import { getSportsbookRegionCookie } from "@tvg/sh-utils/sessionUtils";
import { isMobile } from "@tvg/sh-utils/mobileUtils";

import { getConfig } from "../../../../utils/getConfig";
import { useLoadModule } from "../../../useLoadModule";
import { UnifiedModules } from "../../../useLoadModule/types";
import { getCoreConfigState } from "../../../../redux/selector";
import { setCoreConfigState } from "../../../../redux/actions";
import { WEBVIEW_EVENT_TYPE } from "../../../../types/webview";

export const useSetCoreConfigRegion = () => {
  const dispatch = useDispatch();
  const coreConfig = useLoadModule(UnifiedModules.CORE_CONFIG, isMobile());

  // Only to trigger again the coreConfig to check if getSportsbookRegionCookie was changed.
  const region = useSelector(getCoreConfigState) || getSportsbookRegionCookie();

  useEffect(() => {
    if (region) {
      if (isMobile()) {
        mediator.ios.dispatch({
          type: WEBVIEW_EVENT_TYPE.SET_REGION,
          payload: {
            region
          }
        });
      }

      dispatch(setCoreConfigState(region));
    }
  }, [region]);

  useEffect(() => {
    if (region && coreConfig) {
      coreConfig.setConfig(getConfig(tvgConf().environment, region));
    }
  }, [coreConfig, region]);
};
