import { Dispatch } from "redux";
import { get } from "lodash";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import mediator from "../../../../../../../utils/mediator";
import { getBalance } from "../../../../../../../utils/wallet/getBalance";
import type { ModalPresenterPayload } from "./types";

export const modalPresenterSubscription = (dispatch: Dispatch) => {
  const eventPresentModal = getUMEvent(UMEvents.PresentModal);
  const eventOpenSettings = getUMEvent(UMEvents.OpenSettings);

  mediator.subscribe(
    eventPresentModal.completion,
    (data: ModalPresenterPayload) => {
      const needToFetchUserDetails: boolean = get(
        data,
        "payload.needToFetchUserDetails",
        false
      );
      const needToFetchBalance: boolean = get(
        data,
        "payload.needToFetchBalance",
        false
      );
      const user = get(data, "payload.user");
      const session = get(data, "payload.session");

      if ((!user || !session) && !isXSell()) {
        return;
      }

      if (needToFetchUserDetails) {
        mediator.dispatch({
          type: eventOpenSettings.completion,
          payload: {
            user,
            session
          }
        });
      }

      if (needToFetchBalance || isXSell()) {
        getBalance(dispatch);
      }
    }
  );
};
