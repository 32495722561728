import { isMobile } from "@tvg/sh-utils/mobileUtils";
import { useUMInstanceInitialized } from "../useLoadModule/hooks/useInitializeModule";
import { UnifiedModules } from "../useLoadModule/types";
import { useSubscriptions } from "./hooks/useSubscriptions";
import { useCoreWebView } from "../useCoreWebView";

export const useCoreModalPresenter = () => {
  const isMobileDevice = isMobile();
  const modalPresenter = useUMInstanceInitialized(
    UnifiedModules.MODAL_PRESENTER,
    isMobileDevice
  );

  const accountInstance = useUMInstanceInitialized(
    UnifiedModules.ACCOUNT,
    isMobileDevice,
    true
  );
  const CoreWebView = useCoreWebView();

  useSubscriptions(modalPresenter, CoreWebView, accountInstance);
};
