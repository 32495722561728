import { UMEventsWallet } from "../../../../../../utils/mediator/types";

export const WALLET_SCREENS = {
  [UMEventsWallet.Deposit]: "depositFundsAsync",
  [UMEventsWallet.Tax]: "showTaxAsync",
  [UMEventsWallet.Transactions]: "showTransactionsAsync",
  [UMEventsWallet.Withdraw]: "withdrawFundsAsync",
  [UMEventsWallet.Activity]: "showActivityAsync",
  [UMEventsWallet.AccountHome]: "showAccountHomeAsync"
};
