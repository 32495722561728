import { UserJurisdiction, UserJurisdictionRow } from "@tvg/api/aw/types";
import { getUserJurisdictions as getUserJurisdictionsService } from "@tvg/api/aw";
import {
  acknowledgementsAcceptance,
  termsAndConditionsAcceptance
} from "@tvg/sh-utils/userJurisdictionUtils";
import {
  getAuthTokenCookie,
  getSportsbookRegionCookie,
  getUserSessionData
} from "@tvg/sh-utils/sessionUtils";
import profileService from "@tvg/api/uam";
import { get } from "lodash";

import type { UserProfile } from "../../../types/account/user";
import type { Compliance } from "./types";

export const getUserJurisdiction = async (
  userId: string,
  sessionToken: string,
  region: string
): Promise<UserJurisdiction | null> => {
  if (!userId || !sessionToken || !region) {
    return null;
  }

  const jurisdictionsDataset = await getUserJurisdictionsService(
    sessionToken,
    userId,
    region
  );

  const userJurisdiction = jurisdictionsDataset.find(
    (item: UserJurisdictionRow) => item.jurisdiction === region
  );

  if (userJurisdiction) {
    const isTCAccepted = termsAndConditionsAcceptance(
      userJurisdiction.terms_and_conditions
    );

    const isACAccepted = acknowledgementsAcceptance(
      userJurisdiction.acknowledgements
    );

    return { ...userJurisdiction, accepted: isTCAccepted && isACAccepted };
  }

  return null;
};

export const checkVerifyUser = async (): Promise<boolean | undefined> => {
  try {
    const { data: userProfile } =
      (await profileService.getFDRUserProfile()) as unknown as {
        data: UserProfile;
      };
    return get(userProfile, "isUserVerified");
  } catch (err) {
    console.warn(`[UM] Check verify user error: ${err}`);
    return undefined;
  }
};

export const checkTermsAndConditions = async (): Promise<
  boolean | undefined
> => {
  const region = getSportsbookRegionCookie();
  const authToken = getAuthTokenCookie();
  const user = getUserSessionData(authToken);

  try {
    const jurisdiction = await getUserJurisdiction(
      user.fdUserId,
      authToken,
      region
    );
    return get(jurisdiction, "accepted");
  } catch (err) {
    console.warn(`[UM] Check terms and conditions error: ${err}`);
    return undefined;
  }
};

export const fetchCompliance = async (): Promise<Compliance> => {
  try {
    const isVerified = await checkVerifyUser();
    if (!isVerified) {
      return { isVerified, accepted: undefined };
    }

    const accepted = await checkTermsAndConditions();
    return { accepted, isVerified };
  } catch (err) {
    console.warn(`[UM] Get User compliance error: ${err}`);
    return { isVerified: undefined, accepted: undefined };
  }
};
