import React, { useState, useCallback, useRef, useEffect } from "react";
import type { WagerType } from "@tvg/ts-types/Wager";
import useIntersectionObserver from "@tvg/custom-hooks/hooks/useIntersect";
import { Icon } from "../../../Icon";
import { Container, ButtonContent } from "./styled-components";
import type { BetTypeExpandableButtonProps } from "./types";
import { AnimatedDropdown } from "./AnimatedDropdown";
import {
  BetTypesButton,
  BetTypesText
} from "../BetTypeButton/styled-components";

const getComponentHeight = () => {
  let headerHeight;
  if (typeof window !== "undefined") {
    headerHeight =
      document.querySelector("header")?.getBoundingClientRect().height || 0;
  }

  return { headerHeight };
};

export const BetTypeExpandableButton: React.FC<
  BetTypeExpandableButtonProps
> = ({
  betTypes,
  selectedValue,
  hasPromo = false,
  hasPromoOptedIn = false,
  isSelected = false,
  qaLabel = betTypes[0].code,
  onPress
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { headerHeight } = getComponentHeight();
  const containerRef = useRef<HTMLDivElement>(null);

  const entry = useIntersectionObserver(containerRef, {
    root: null,
    rootMargin: `${-((headerHeight || 0) + 3)}px`,
    threshold: 1.0
  });

  const onClick = useCallback(() => {
    setIsDropdownOpen((prevState) => !prevState);
  }, []);

  const onSelectValue = (val: number) => {
    onPress(val);
    setIsDropdownOpen(false);
  };

  const onHoverOut = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const onHoverIn = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  const getTabLabel = () =>
    betTypes.find((type) => type.id === selectedValue) as WagerType;

  const hoverHandlers = {
    onMouseEnter: () => onHoverIn(),
    onMouseLeave: () => onHoverOut()
  };

  const dropdownPosition = () => {
    if (entry) {
      const { top, height, left, width } = entry.target.getBoundingClientRect();

      return {
        top: entry.isIntersecting
          ? window.scrollY + top + height
          : top + height,
        left,
        width
      };
    }
    return {
      top: 0,
      left: 0,
      width: 0
    };
  };

  // close dropdown when user scrolls in the app
  useEffect(() => {
    const handleDropdownToClose = () => setIsDropdownOpen(false);
    if (window !== undefined) {
      window.addEventListener("scroll", handleDropdownToClose);
    }
    return () => {
      if (window !== undefined) {
        window.removeEventListener("scroll", handleDropdownToClose);
      }
    };
  }, []);

  return (
    <Container ref={containerRef} {...hoverHandlers}>
      <BetTypesButton
        onClick={onClick}
        isSelected={isSelected}
        data-qa-label={`${qaLabel}${isSelected ? "-active" : ""}`}
      >
        <ButtonContent>
          {hasPromo && (
            <Icon
              pr="space-1"
              qaLabel="bet-type-expandable-icon-promo"
              name="promo"
              size="s"
              lineColor={
                hasPromoOptedIn
                  ? "--fd-colors-system-positive-content-accent"
                  : "--fd-colors-system-alert-content-accent"
              }
              backgroundColor={
                hasPromoOptedIn
                  ? "--fd-colors-system-positive-background-subtle"
                  : "--fd-colors-system-alert-background-subtle"
              }
            />
          )}
          <BetTypesText isSelected={isSelected}>
            {getTabLabel() ? getTabLabel().name : betTypes[0].name}
          </BetTypesText>
          <Icon
            ml="auto"
            name={isDropdownOpen ? "chevronUp" : "chevronDown"}
            size="s"
            lineColor={
              isSelected
                ? "--fd-colors-content-link"
                : "--fd-colors-content-default"
            }
          />
        </ButtonContent>
      </BetTypesButton>
      <AnimatedDropdown
        isTop={!entry?.isIntersecting}
        isDropdownVisible={isDropdownOpen}
        onSelectValue={onSelectValue}
        selectedValue={selectedValue}
        values={betTypes}
        {...hoverHandlers}
        {...dropdownPosition()}
        qaLabel="bet-type-dropdown"
      />
    </Container>
  );
};
