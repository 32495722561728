import mediatorScoped from "../../../../../../../utils/mediator";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import { AccountModule } from "../../../../../../useLoadModule/types";
import { getUMEvent } from "../../../../../../../utils/mediator/events";

export const webHomepageSubscription = (accountInstance: AccountModule) => {
  const event = getUMEvent(UMEvents.OpenSettings);

  mediatorScoped.subscribe(event.trigger, () => {
    accountInstance.presentAccountSettingsAsync().then(async () => {
      const session = await accountInstance.getSessionAsync("Silent");
      const user = await accountInstance.getUserAsync("Silent");

      mediatorScoped.dispatch({
        type: event.completion,
        payload: {
          user,
          session
        }
      });
    });
  });
};
