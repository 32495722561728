import type { Store } from "redux";
import { get } from "lodash";
import { UnifiedModules } from "../../hooks/useLoadModule/types";

const reducerRootSelector = "unifiedModules";

export const isUMInitialized =
  (module: UnifiedModules) =>
  (store: Store): boolean =>
    get(store, `${reducerRootSelector}.${module}.isInitialized`, false);

export const getCoreConfigState = (store: Store): string | undefined =>
  get(store, `${reducerRootSelector}.CoreConfig.state`);

export const getIsModalOpened = (store: Store) =>
  get(store, `${reducerRootSelector}.Account.isOpened`, false);
