import { useState, useEffect } from "react";
import tvgConf from "@tvg/conf";
import type { CoreEventsModule } from "../../../useLoadModule/types";
import { logger } from "./utils/debugger";

const VENDOR_TAG_LOGS = "UMLogs";

export const useLogsSubscribe = (
  coreEventsInstance: CoreEventsModule | null
) => {
  const [isSubscribing, setSubscribing] = useState(false);

  useEffect(() => {
    if (!isSubscribing && coreEventsInstance) {
      setSubscribing(true);

      // At this moment we only use to debug, but probably in the future we want to use to create logs in amplitude or something else
      const env = tvgConf().environment;

      if (env !== "production") {
        coreEventsInstance.subscribe((event) => {
          if (event.vendorTag === VENDOR_TAG_LOGS) {
            logger(event);
          }
        });
      }
    }
  }, [coreEventsInstance, isSubscribing]);
};
