import { isMobile } from "@tvg/sh-utils/mobileUtils";
import {
  useKeepUserSessionAliveByClick,
  useKeepUserSessionAliveByVideo
} from "./hooks/useKeepSessionAlive";
import { useUMInstanceInitialized } from "../useLoadModule/hooks/useInitializeModule";
import { UnifiedModules } from "../useLoadModule/types";
import { useSubscriptions } from "./hooks/useSubscriptions";

export const useAccount = () => {
  const isMobileDevice = isMobile();
  const accountInstance = useUMInstanceInitialized(
    UnifiedModules.ACCOUNT,
    isMobileDevice
  );

  useSubscriptions(accountInstance);
  useKeepUserSessionAliveByClick();
  useKeepUserSessionAliveByVideo();
};
