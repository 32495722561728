import { Dispatch } from "redux";
import { get } from "lodash";
import { setUserTermsAndCondition } from "@fdr/shared-actions/UserActions";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import mediatorScoped from "../../../../../../../utils/mediator";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import { checkTermsAndConditions } from "../../../../../../../utils/account/getUserCompliance/service";
import type { AcceptTermsPayload } from "./types";

export const acceptTermsSubscription = (dispatch: Dispatch) => {
  const event = getUMEvent(UMEvents.AcceptTerms);

  mediatorScoped.subscribe(
    event.completion,
    async (data: AcceptTermsPayload) => {
      const needsUpdate = get(data, "payload.needsUpdate", false);
      let isAccepted = false;
      if (!needsUpdate) {
        isAccepted = get(data, "payload.isAccepted", false);
      } else {
        const newTermsStatus = await checkTermsAndConditions();
        isAccepted = !!newTermsStatus;
      }

      dispatch(setUserTermsAndCondition(isAccepted));
    }
  );
};
