import type { ICoreConfig } from "@fanduel/core-config-contract";
import type { IMutableCoreConfig } from "@fanduel/core-config/src/types";
import type { ICorePxInitializer } from "@fanduel/core-px/src/types";
import type { ICorePx } from "@fanduel/core-px-contract";
import type { NullaryFn } from "@tvg/ts-types/Functional";
import type { IAccount } from "@fanduel/account-contract";
import type { IAccountOwner } from "@fanduel/account/lib/typescript/types";
import type { IModalPresenter } from "@fanduel/core-modal-presenter-contract";
import type { IModalPresenterOwner } from "@fanduel/core-modal-presenter";
import type { IWalletOwner } from "@fanduel/wallet";
import type { IWallet } from "@fanduel/wallet-contract";
import type { CoreWebViewProps, ICoreWebView } from "@fanduel/core-webview";
import type { IAmplitudeUM } from "@fanduel/amplitude-um-contract";
import type { IAmplitudeUMOwner } from "@fanduel/amplitude-um/src/types";
import type { ForwardRefExoticComponent, RefAttributes } from "react";
import type { ICoreApiIdentities } from "@fanduel/core-api-identities-contract";
import type { ICoreApiIdentitiesOwner } from "@fanduel/core-api-identities/lib/typescript/types";
import type { ICoreEventsOwner } from "@fanduel/core-events/lib/typescript/types";
import type { ICoreEvents } from "@fanduel/core-events-contract";

export type LazyModuleLoader<T> = NullaryFn<Promise<T>>;

export type CoreConfigModule = ICoreConfig & IMutableCoreConfig;
export type CorePxModule = ICorePx & ICorePxInitializer;
export type AccountModule = IAccountOwner & IAccount;
export type ModalPresenterModule = IModalPresenter & IModalPresenterOwner;
export type WalletModule = IWalletOwner & IWallet;
export type CoreWebviewModule = ForwardRefExoticComponent<
  CoreWebViewProps & RefAttributes<ICoreWebView>
>;
export type AmplitudeModule = IAmplitudeUM & IAmplitudeUMOwner;
export type CoreApiIdentitiesModule = ICoreApiIdentities &
  ICoreApiIdentitiesOwner;
export type CoreEventsModule = ICoreEventsOwner & ICoreEvents;

export enum UnifiedModules {
  CORE_CONFIG = "CoreConfig",
  CORE_PX = "CorePx",
  CORE_API_IDENTITIES = "CoreApiIdentities",
  ACCOUNT = "Account",
  MODAL_PRESENTER = "ModalPresenter",
  WALLET = "Wallet",
  CORE_WEBVIEW = "CoreWebView",
  AMPLITUDE = "Amplitude",
  CORE_EVENTS = "CoreEvents"
}
export interface LazyMappedModule {
  [UnifiedModules.CORE_CONFIG]: LazyModuleLoader<CoreConfigModule>;
  [UnifiedModules.CORE_PX]: LazyModuleLoader<CorePxModule>;
  [UnifiedModules.CORE_API_IDENTITIES]: LazyModuleLoader<CoreApiIdentitiesModule>;
  [UnifiedModules.ACCOUNT]: LazyModuleLoader<AccountModule>;
  [UnifiedModules.MODAL_PRESENTER]: LazyModuleLoader<ModalPresenterModule>;
  [UnifiedModules.WALLET]: LazyModuleLoader<WalletModule>;
  [UnifiedModules.CORE_WEBVIEW]: LazyModuleLoader<CoreWebviewModule>;
  [UnifiedModules.AMPLITUDE]: LazyModuleLoader<AmplitudeModule>;
  [UnifiedModules.CORE_EVENTS]: LazyModuleLoader<CoreEventsModule>;
}

export interface MappedModules {
  [UnifiedModules.CORE_CONFIG]: CoreConfigModule;
  [UnifiedModules.CORE_PX]: CorePxModule;
  [UnifiedModules.CORE_API_IDENTITIES]: CoreApiIdentitiesModule;
  [UnifiedModules.ACCOUNT]: AccountModule;
  [UnifiedModules.MODAL_PRESENTER]: ModalPresenterModule;
  [UnifiedModules.WALLET]: WalletModule;
  [UnifiedModules.CORE_WEBVIEW]: CoreWebviewModule;
  [UnifiedModules.AMPLITUDE]: AmplitudeModule;
  [UnifiedModules.CORE_EVENTS]: CoreEventsModule;
}
