import * as React from "react";

const TemplateSelectorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    fill="none"
    viewBox="0 -2 44 44"
  >
    <g stroke="#fff" strokeWidth="1.5" clipPath="url(#clip0_710_84595)">
      <path d="m23.776 17.314-8 8v3h3l8-8m-3-3 3 3m-3-3 1.19-1.19a1.5 1.5 0 0 1 2.12 0l.88.879a1.5 1.5 0 0 1 0 2.121l-1.19 1.19" />
      <path
        strokeLinejoin="round"
        d="m19.09 22.001-3.45-3.45 2.998-2.999 3.45 3.45M25.087 22.001l3.45 3.45-2.998 2.999L22.09 25M18.863 18.776l1.503-1.503M25.313 25.225l1.503-1.502"
      />
    </g>
    <defs>
      <clipPath id="clip0_710_84595">
        <path fill="#fff" d="M14 14h16v16H14z" />
      </clipPath>
    </defs>
  </svg>
);

export default TemplateSelectorIcon;
