import { formatUser } from "./formatUser";
import { formatSession } from "./formatSession";
import type { FormatAuthDetails } from "./types";

export const formatAuthDetail = ({
  user,
  session,
  authToken
}: FormatAuthDetails) => ({
  user: user || formatUser(authToken),
  session: session || formatSession(authToken)
});
