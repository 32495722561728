import type { MutableRefObject } from "react";
import { Dispatch } from "redux";

import type { ExtraProps } from "./types";
import { launchInRegionSubscribe } from "./launchInRegion";

export const xsellAccountSubscriptions = (
  dispatch: Dispatch,
  extraProps: MutableRefObject<ExtraProps>
) => {
  launchInRegionSubscribe(dispatch, extraProps);
};
