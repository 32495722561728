import type { UserJurisdiction } from "@tvg/api/aw/types";
import type { Session, User } from "@fanduel/account-contract";
import { getUserJurisdiction } from "../../../../../../utils/account/getUserCompliance/service";

export const getUserJurisdictionByAccount = async (
  user: User,
  session: Session,
  region: string
): Promise<UserJurisdiction | null> =>
  getUserJurisdiction(user.userId, session.token, region);
