import { get } from "lodash";
import type { AmplitudeModule } from "../../../../../../useLoadModule/types";
import { SetSessionIdPayload } from "./type";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import mediatorScoped from "../../../../../../../utils/mediator";

export const webSetSessionIdSubscription = (amplitude: AmplitudeModule) => {
  const event = getUMEvent(UMEvents.SetSessionId);

  mediatorScoped.subscribe(event.trigger, (data: SetSessionIdPayload) => {
    const deviceId: string | undefined = get(data, "payload.deviceId");
    amplitude.setSessionId(deviceId);
  });
};
