import mediatorScoped from "../../../../../../../utils/mediator";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import { AccountModule } from "../../../../../../useLoadModule/types";
import { getUMEvent } from "../../../../../../../utils/mediator/events";

export const webLogoutSubscription = (accountInstance: AccountModule) => {
  const event = getUMEvent(UMEvents.Logout);

  mediatorScoped.subscribe(event.trigger, () => {
    accountInstance.clearSession();
    mediatorScoped.dispatch({
      type: event.completion
    });
  });
};
