import React from "react";
import DOMPurify from "dompurify";
import { Paragraph, Button, IconButton } from "@tvg/design-system/web";
import {
  HeaderContainer,
  CloseSelectorWrapper,
  CloseContent,
  IconWrapper
} from "./styled-components";

interface CloseModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setIsTemplatesModalOpen: (value: boolean) => void;
  onClose: () => void;
  onDismiss: () => void;
  handleHPTemplateSelectorVisibility: () => void;
  prefShowHPTemplateSelector: boolean;
  description: string;
  title: string;
  isMobile: boolean;
}

export const CloseModalContent: React.FC<CloseModalProps> = ({
  isOpen,
  onClose,
  onDismiss,
  title,
  description,
  isMobile
}) => {
  const sanitizedDescription = DOMPurify.sanitize(description);

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  if (!isOpen) return null;

  return (
    <CloseSelectorWrapper
      data-qa-label="close-modal-wrapper"
      onClick={stopPropagation}
    >
      {!isMobile && (
        <HeaderContainer>
          <Paragraph
            fontSize="l"
            fontFamily="bold"
            color="--fd-colors-content-strong"
            padding="var(--fd-space-space-5)"
            qaLabel="close-modal-title"
            flex={1}
            textAlign="center"
            margin={0}
          >
            {title}
          </Paragraph>
          <IconWrapper>
            <IconButton
              iconName="close"
              qaLabel="dismiss-modal-close"
              color="--fd-colors-content-default"
              size="s"
              onClick={onClose}
              marginRight={6}
            />
          </IconWrapper>
        </HeaderContainer>
      )}
      <CloseContent>
        <Paragraph
          fontSize="m"
          fontFamily="regular"
          color="--fd-colors-content-strong"
          paddingTop="var(--fd-space-space-4)"
          paddingBottom="var(--fd-space-space-3)"
          qaLabel="close-modal-description"
        >
          <span dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
        </Paragraph>
        <Button
          variant="primary"
          onClick={onDismiss}
          qaLabel="close-button"
          size="m"
          isStretched
          padding="var(--fd-space-space-5)"
          marginTop="var(--fd-space-space-3)"
        >
          Close Banner
        </Button>
        <Button
          variant="secondary"
          onClick={onClose}
          qaLabel="cancel-button"
          size="m"
          isStretched
          padding="var(--fd-space-space-5)"
          marginTop="var(--fd-space-space-2)"
        >
          Cancel
        </Button>
      </CloseContent>
    </CloseSelectorWrapper>
  );
};

export default CloseModalContent;
