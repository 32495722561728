import { isMobile } from "@tvg/sh-utils/mobileUtils";
import { useUMInstanceInitialized } from "../useLoadModule/hooks/useInitializeModule";
import { UnifiedModules } from "../useLoadModule/types";
import { useLogsSubscribe } from "./hooks/useLogsSubscribe";

export const useCoreEvents = () => {
  const isMobileDevice = isMobile();
  const coreEventsInstance = useUMInstanceInitialized(
    UnifiedModules.CORE_EVENTS,
    isMobileDevice
  );

  useLogsSubscribe(coreEventsInstance);
};
