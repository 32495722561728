import styled from "styled-components";

export const TemplateListContainer = styled.ul``;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--fd-space-space-3);
  gap: var(--fd-space-space-2);
`;
