/*
 * ACTION TYPES
 */
export type ErrorTypes =
  | "DEFAULT_ERROR"
  | "LOCATION_ERROR"
  | "UNAVAILABLE_LOCATION_ERROR"
  | "WRONG_LOCATION_ERROR";

export type LocationStatusTypes = "success" | "pending" | "empty";

export type LocationUnderFlow = "LOGIN" | "SIGN_UP";

export enum StatusLocationUnderFlow {
  IDLE,
  ASKED,
  COMPLETED
}

export type ActionTypes =
  | "SET_LOADING_LOCATION_SPLASH"
  | "ALLOW_LOCATION_SPLASH"
  | "ERROR_LOCATION_SPLASH"
  | "FDR_GHOST_ACCOUNT_EXCEPTION"
  | "INVALID_WAGERING_STATE_LOCATION_SPLASH"
  | "SET_LOCATION_STATUS"
  | "SET_LOCATION_BEHIND_SCENES"
  | "SET_LOCATION_REQUIRED"
  | "SET_LOCATION_UNDER_FLOW"
  | "RESET_STATE"
  | "GEO_LOCATION_GET"
  | "RG_EXCLUSION_SPLASH"
  | "SET_APP_VERSION_OUTDATED"
  | "WALKTHROUGH_NOT_FOUND"
  | "STATUS_LOCATION_UNDER_FLOW";

export type Action = {
  type: ActionTypes;
};

export type AllowLocationAction = {
  type: ActionTypes;
  payload: {
    isOpen: boolean;
  };
};

export interface ActionSetStatusLocationUnderFlow {
  type: ActionTypes;
  payload: {
    status: StatusLocationUnderFlow;
  };
}

export type AppVersionOutdatedAction = {
  type: ActionTypes;
};

export type ErrorLocationAction = {
  type: ActionTypes;
  payload: {
    error: string;
  };
};

export type GhostAccountExceptionAction = {
  type: ActionTypes;
};

export type LocationStatusAction = {
  type: ActionTypes;
  payload: {
    status: LocationStatusTypes;
  };
};

export type LocationBehindScenesAction = {
  type: ActionTypes;
  payload: {
    isLocationBehindTheScenes: boolean;
  };
};

export type LocationRequiredAction = {
  type: ActionTypes;
  payload: {
    isLocationRequired: boolean;
  };
};

export type LocationUnderLoginFlowAction = {
  type: ActionTypes;
  payload: {
    locationUnderFlow: LocationUnderFlow;
  };
};

export type RGExclusionAction = {
  type: ActionTypes;
  payload: {
    exclusionType: string;
    exclusionEndDate: string;
  };
};

export type SetBlockedCountry = {
  type: "SET_BLOCKED_COUNTRY";
  payload: boolean;
};

export type SetAndroidBlockedState = {
  type: "ANDROID_BLOCKED_STATE";
};

export type SetEmptyErrorState = {
  type: "CLEAR_ERROR_SPLASH";
};

export type WalkthroughNotFoundAction = {
  type: "WALKTHROUGH_NOT_FOUND";
};

/*
 * REDUCER TYPES
 */
export type Actions =
  | Action
  | AllowLocationAction
  | ErrorLocationAction
  | GhostAccountExceptionAction
  | LocationStatusAction
  | LocationBehindScenesAction
  | LocationRequiredAction
  | LocationUnderLoginFlowAction
  | RGExclusionAction
  | SetBlockedCountry
  | SetAndroidBlockedState
  | SetEmptyErrorState
  | WalkthroughNotFoundAction
  | ActionSetStatusLocationUnderFlow;

export type SplashType =
  | "ALLOW_LOCATION"
  | "INVALID_WAGERING_STATE"
  | "TIMEOUT"
  | "SELF_EXCLUSION"
  | "SUSPEND"
  | "ERROR"
  | "BLOCKED_COUNTRY"
  | "ANDROID_BLOCKED_STATE"
  | "CLEAR_ERROR_SPLASH"
  | "APP_VERSION_OUTDATED"
  | "FDR_GHOST_ACCOUNT_EXCEPTION"
  | "WALKTHROUGH_NOT_FOUND";

export type State = {
  isOpen: boolean;
  isLoading: boolean;
  splashType: SplashType;
  error?: ErrorTypes | null;
  isLocationRequired: boolean;
  isLocationBehindTheScenes: boolean;
  status: LocationStatusTypes;
  locationUnderFlow?: string;
  exclusionEndDate?: string;
  statusLocationUnderFlow?: StatusLocationUnderFlow;
};

export type IOSForceUpdateFDR = {
  forceUpdate: boolean;
  minimumVersion: string;
  forceUpdateAndroid: boolean;
  minimumVersionAndroid: string;
  title: string;
  message: string;
  primaryButtonTitle: string;
};

export type GhostAccountExceptionFDR = {
  title?: string;
  message: string;
  primaryButtonTitle: string;
  secundaryButtonTitle: string;
};

export type WalkthroughNotFoundMessage = {
  title?: string;
  message: string;
  primaryButtonTitle: string;
  secundaryButtonTitle: string;
};
