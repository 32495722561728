import { get } from "lodash";
import type { MutableRefObject } from "react";
import mediatorScoped from "../../../../../../../utils/mediator";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import { getSession, logout } from "../../../../../../../utils/account";
import type { HotReloadPayload, ExtraProps } from "./types";

export const hotReloadSubscription = (
  extraProps: MutableRefObject<Partial<ExtraProps>>
) => {
  const event = getUMEvent(UMEvents.HotReload);

  mediatorScoped.subscribe(event.completion, (data: HotReloadPayload) => {
    const hasSession = get(data, "payload.hasValidSession");

    if (hasSession && !extraProps.current.isLogged) {
      getSession("Silent");
    } else if (!hasSession && extraProps.current.isLogged) {
      logout();
    }
  });
};
