import { get } from "lodash";
import { Dispatch } from "redux";
import type { MutableRefObject } from "react";
import { getSportsbookRegionCookie } from "@tvg/sh-utils/sessionUtils";
import mediatorScoped from "../../../../../../../utils/mediator";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import { updateUserDetails } from "../../../services/updateUserDetails";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import type { ExtraProps, HomepagePayload } from "./types";

export const homepageSubscription = (
  dispatch: Dispatch,
  extraProps: MutableRefObject<Partial<ExtraProps>>
) => {
  const event = getUMEvent(UMEvents.OpenSettings);

  mediatorScoped.subscribe(event.completion, (data: HomepagePayload) => {
    const user = get(data, "payload.user");
    const session = get(data, "payload.session");

    updateUserDetails({
      user,
      session,
      dispatch,
      region: extraProps.current.region || getSportsbookRegionCookie(),
      isVerified: extraProps.current.isVerified,
      enforceAcceptTerms: extraProps.current.enforceAcceptTerms,
      enableCPPPromos: extraProps.current.enableCPPPromos
    });
  });
};
