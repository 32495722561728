import { MutableRefObject, useEffect, useRef } from "react";

export const useVariablesRefs = <T>(
  variablesToSave: T
): MutableRefObject<T> => {
  const variablesStoraged = useRef<T>({} as T);

  useEffect(() => {
    variablesStoraged.current = variablesToSave;
  }, [variablesToSave]);

  return variablesStoraged;
};
