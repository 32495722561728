import { get } from "lodash";
import { Store } from "redux";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import { isMobile } from "@tvg/sh-utils/mobileUtils";
import {
  HomepageStoryblokConfig,
  HomepageStoryblokMobileConfig
} from "../types";
import { homeStoryblokConfigMock } from "../__mocks__/homeStoryblokConfigMock";
import { homeStoryblokConfigMobileMock } from "../__mocks__/homeStoryblokConfigMobileMock";
import {
  userExperienceProfileSelector,
  HPBannerCloseModalMessages
} from "./defaultValues";

export const getHomepageTemplates = (
  store: Store
): HomepageStoryblokConfig | HomepageStoryblokMobileConfig =>
  get(
    store,
    "homepageTemplates",
    isMobile() ? homeStoryblokConfigMobileMock : homeStoryblokConfigMock
  );

export const getShowHomeOrderSBMobile = (store: Store): boolean =>
  get(store, "capi.featureToggles.showStoryblokHomepage", false);

export const getEnableHorseRacing101 = (store: Store): boolean =>
  get(store, "capi.featureToggles.horseRacing101", false);

export const getEnableHomepagePillsNav = (store: Store) =>
  get(store, "capi.featureToggles.enableHomepagePillsNav", false);

export const getHasCrossNavigation = (store: Store) =>
  get(store, "app.hasCrossNavigation", false);

export const getTemplateUserPreference = (store: Store): string =>
  get(store, "homepageTemplates.templateUserPreference", "default");

export const getQuickLinks = (store: Store) =>
  get(store, "quickLinks.content.links", []);

export const getUserExperienceProfileSelector = (store: Store) =>
  parseCAPIMessage(
    store,
    "capi.messages.userExperienceProfileSelector",
    userExperienceProfileSelector
  );

export const getCloseModalMessage = (store: Store) =>
  parseCAPIMessage(
    store,
    "capi.messages.HPBannerCloseModal",
    HPBannerCloseModalMessages
  );
