import React, { useCallback, useEffect } from "react";
import mediator from "@tvg/mediator";
import { useSelector } from "react-redux";
// @ts-ignore
import { useNavigate, useLocation } from "react-router-dom";
import { get, noop } from "lodash";
// @ts-ignore
import { getIsLogged } from "@urp/store-selectors";
import {
  Logo,
  LogoProps,
  useMediaQuery,
  breakpoints
} from "@tvg/design-system";
import { Button, IconButton } from "@tvg/design-system/web";
import TVGConf from "@tvg/conf";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { createConversation, events as AlchemerEvents } from "@urp/alchemer";
import { useTvg5 } from "@urp/amplitude-experiments";

import { UserInfo } from "@tvg/ts-types/User";
import {
  getAuthTokenCookie,
  getUserSessionData,
  isAccountCompliantSelector
} from "@tvg/sh-utils/sessionUtils";
import { getUserCompliance } from "@urp/unified-modules/src/utils/account/getUserCompliance";
import { isFDR } from "@tvg/utils/generalUtils";
import { getURPEducationHubMessages } from "@urp/education-hub/src/selectors";
import { getSession } from "@urp/unified-modules/src/utils/account";
import DropdownMenu from "../DropdownMenu";
import IconButtonList from "../IconButtonList";
import QuickDepositButton from "../QuickDepositButton";
import { menuItemsFdr, menuItemsTvg } from "../menuItemsDefault";
import MyBetsButton from "../MyBetsButton";
import { MenuItems } from "../types";
import {
  enableURPEducationHub,
  enableURPWagerPad,
  enableURPWatchFDTV
} from "../../store/selectors";
import {
  LogoContainer,
  Container,
  LeftColumn,
  RightColumn,
  HamburgerButtonContainer
} from "./styled-components";
import WagerpadButton from "../WagerPadButton";
import { updateRouteByToggle } from "../utils";

export interface StandardHeaderProps {
  onClickHamburger: () => void;
  activeBets: number | undefined;
  logoBrand?: LogoProps["brand"];
}

const StandardHeader = ({
  onClickHamburger,
  activeBets,
  logoBrand = "tvg"
}: StandardHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isURPWagerPadEnabled = useSelector(enableURPWagerPad);
  const isAccountCompliant = useSelector(isAccountCompliantSelector);
  const isLogged: boolean = useSelector(getIsLogged);
  const isURPWatchFDTVEnabled = useSelector(enableURPWatchFDTV);
  const isFdr = isFDR();
  const isURPEducationHubEnable = useSelector(enableURPEducationHub);
  const urpEducationHubMessages = useSelector(getURPEducationHubMessages);
  const menuItems = useSelector((store) =>
    parseCapiMessage(
      store,
      "capi.messages.DesktopHeaderMenuItems",
      isFdr ? menuItemsFdr : menuItemsTvg
    )
  );

  const user = useSelector((store) => get(store, "userData.user", null));

  const isDesktopMinXl = useMediaQuery(breakpoints.desktop.min.xl);
  const isDesktopMinSm = useMediaQuery(breakpoints.desktop.min.sm);
  const isTablet = TVGConf().device === "tablet";

  if (typeof window !== "undefined") {
    useTvg5(get(user, "accountNumber", ""));
  }

  const callLogin = useCallback(() => {
    if (isFdr) {
      getSession("ForceNew", noop, "join_bar");
    } else {
      mediator.base.dispatch({ type: "TVG_LOGIN:OPEN_LOGIN_MODAL" });
    }
  }, []);

  useEffect(() => {
    mediator.base.subscribe(
      "TVG_LOGIN:LOGIN_SUCCESS",
      ({ userData }: { userData: UserInfo }) => {
        if (userData && !isFDR) {
          createConversation(userData);
          AlchemerEvents.login();
        }
      }
    );
  }, []);

  const goToRegistration = useCallback(() => {
    if (isFdr) {
      getSession("NewUser", noop, "join_bar");
    } else {
      navigate("/registration");
    }
  }, []);

  useEffect(() => {
    if (user && user.accountNumber) {
      if (isFdr) {
        const authToken = getAuthTokenCookie();
        const { fdUserId } = getUserSessionData(authToken);
        createConversation({ ...user, accountNumber: fdUserId });
      } else {
        createConversation(user);
      }
    }
  }, [user]);

  const logoProps = TVGConf().brand === "iowa" ? { height: 30 } : {};

  return (
    <Container>
      <LeftColumn data-qa-label="headerLeftColumn">
        {(isTablet || !isDesktopMinXl || typeof window === "undefined") && (
          <HamburgerButtonContainer mediaQuery={breakpoints.desktop.min.xl}>
            <IconButton
              mode="dark"
              iconName="hamburger"
              qaLabel="hamburger-button"
              onClick={onClickHamburger}
              mr="space-5"
            />
          </HamburgerButtonContainer>
        )}
        <LogoContainer
          to="/"
          onClick={() => AlchemerEvents.selectTVGLogo()}
          data-qa-label="tvgHomeButton"
        >
          <Logo isDarkMode brand={logoBrand} {...logoProps} />
        </LogoContainer>
        {!isTablet &&
          (isDesktopMinXl || typeof window === "undefined") &&
          menuItems.map((item: MenuItems, index: number) => (
            <DropdownMenu
              key={`dropdown-menu-${index.toString()}`}
              title={item.name}
              route={updateRouteByToggle(item.route, {
                isURPEducationHubEnable,
                urpEducationHubMessages
              })}
              target={item.options && item.options.target}
              qaLabel={item.qaLabel}
              subMenus={item.subMenus}
            />
          ))}
      </LeftColumn>
      {typeof isLogged !== "undefined" ? (
        <RightColumn data-qa-label="headerRightColumn">
          <IconButtonList />
          <Button
            icon="liveVideo"
            variant="secondaryDark"
            qaLabel="watchLiveButton"
            isDisabled={
              isURPWatchFDTVEnabled && location.pathname.includes("/live")
            }
            onClick={() => {
              if (isURPWatchFDTVEnabled) {
                return navigate("/live");
              }

              if (isFdr) {
                if (!isLogged) {
                  return mediator.base.dispatch({
                    type: "TVG_LOGIN:OPEN_LOGIN_MODAL",
                    payload: {
                      callback: () =>
                        window.open("https://fanduel.com/watch", "_blank")
                    }
                  });
                }
                if (!isAccountCompliant) {
                  return getUserCompliance();
                }
                return window.open("https://fanduel.com/watch", "_blank");
              }
              return window.open("/live", "_blank");
            }}
          >
            {(isLogged && isDesktopMinSm) || !isLogged || isTablet ? (
              "Watch FDTV"
            ) : (
              <></>
            )}
          </Button>

          {isLogged ? (
            <>
              <QuickDepositButton />
              <MyBetsButton activeBets={activeBets} />
              {(!isFdr || isURPWagerPadEnabled) && (
                <WagerpadButton
                  isTablet={isTablet}
                  isNewWagerpad={isURPWagerPadEnabled}
                />
              )}
            </>
          ) : (
            <>
              <Button
                mx="space-5"
                variant="secondaryDark"
                icon="lock"
                onClick={callLogin}
                qaLabel="login-button"
              >
                Log In
              </Button>
              <Button
                icon="userPlus"
                variant="primary"
                onClick={goToRegistration}
                qaLabel="signup-button"
              >
                Join now
              </Button>
            </>
          )}
        </RightColumn>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default StandardHeader;
