import {
  AllowLocationAction,
  ErrorLocationAction,
  LocationStatusTypes,
  LocationStatusAction,
  Action,
  ErrorTypes,
  LocationRequiredAction,
  LocationBehindScenesAction,
  LocationUnderLoginFlowAction,
  RGExclusionAction,
  SetBlockedCountry,
  SetAndroidBlockedState,
  SetEmptyErrorState,
  AppVersionOutdatedAction,
  LocationUnderFlow,
  GhostAccountExceptionAction,
  WalkthroughNotFoundAction,
  ActionSetStatusLocationUnderFlow,
  StatusLocationUnderFlow
} from "./types";

export const setAndroidBlockedState = (): SetAndroidBlockedState => ({
  type: "ANDROID_BLOCKED_STATE"
});

export const clearSplashErrors = (): SetEmptyErrorState => ({
  type: "CLEAR_ERROR_SPLASH"
});

export const loadingLocationSplashAction = (): Action => ({
  type: "SET_LOADING_LOCATION_SPLASH"
});

export const setBlockedCountry = (
  isLocationRequired: boolean
): SetBlockedCountry => ({
  type: "SET_BLOCKED_COUNTRY",
  payload: isLocationRequired
});

export const allowLocationSplashAction = (
  isOpen: boolean = false
): AllowLocationAction => ({
  type: "ALLOW_LOCATION_SPLASH",
  payload: {
    isOpen
  }
});

export const setLocationSplashOutdatedAppVersionAction =
  (): AppVersionOutdatedAction => ({
    type: "SET_APP_VERSION_OUTDATED"
  });

export const errorLocationSplashAction = (
  error: ErrorTypes
): ErrorLocationAction => ({
  type: "ERROR_LOCATION_SPLASH",
  payload: {
    error
  }
});

export const fdrGhostAccountExceptionAction =
  (): GhostAccountExceptionAction => ({
    type: "FDR_GHOST_ACCOUNT_EXCEPTION"
  });

export const walkthroughNotFoundAction = (): WalkthroughNotFoundAction => ({
  type: "WALKTHROUGH_NOT_FOUND"
});

export const invalidWageringStateLocationSplashAction = (): Action => ({
  type: "INVALID_WAGERING_STATE_LOCATION_SPLASH"
});

export const setLocationStatusAction = (
  status: LocationStatusTypes
): LocationStatusAction => ({
  type: "SET_LOCATION_STATUS",
  payload: {
    status
  }
});

export const setLocationBehindScenesAction = (
  isLocationBehindTheScenes: boolean
): LocationBehindScenesAction => ({
  type: "SET_LOCATION_BEHIND_SCENES",
  payload: {
    isLocationBehindTheScenes
  }
});

export const setLocationRequiredAction = (
  isLocationRequired: boolean
): LocationRequiredAction => ({
  type: "SET_LOCATION_REQUIRED",
  payload: {
    isLocationRequired
  }
});

export const setLocationUnderFlowAction = (
  locationUnderFlow: LocationUnderFlow
): LocationUnderLoginFlowAction => ({
  type: "SET_LOCATION_UNDER_FLOW",
  payload: {
    locationUnderFlow
  }
});

export const rgExclusionSplashAction = (
  exclusionType: string,
  exclusionEndDate: string
): RGExclusionAction => ({
  type: "RG_EXCLUSION_SPLASH",
  payload: {
    exclusionType,
    exclusionEndDate
  }
});

export const resetStateLocationSplashAction = (): Action => ({
  type: "RESET_STATE"
});

export const setStatusLocationUnderFlow = (
  status: StatusLocationUnderFlow
): ActionSetStatusLocationUnderFlow => ({
  type: "STATUS_LOCATION_UNDER_FLOW",
  payload: {
    status
  }
});
