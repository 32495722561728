import mediator from "../../mediator";
import { UMEvents } from "../../mediator/types";
import { getUMEvent } from "../../mediator/events";

export const openUserHomepage = () => {
  const event = getUMEvent(UMEvents.OpenSettings);

  mediator.dispatch({
    type: event.trigger
  });
};
