import mediator from "../../mediator";
import { UMEvents } from "../../mediator/types";
import { getUMEvent } from "../../mediator/events";
import type { Config } from "./types";

export const presentModal = (config: Config) => {
  const event = getUMEvent(UMEvents.PresentModal);
  mediator.dispatch({
    type: event.trigger,
    payload: config
  });
};
