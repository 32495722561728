import { get } from "lodash";
import {
  AccountModule,
  WalletModule
} from "../../../../../useLoadModule/types";
import { getUMEvent } from "../../../../../../utils/mediator/events";
import type { UMEventsWallet } from "../../../../../../utils/mediator/types";
import { WALLET_SCREENS } from ".";
import mediator from "../../../../../../utils/mediator";
import { ScreenStatus } from "./types";

// Workaround -> Maybe we can remove this when Wallet Library do a fix.
let screenStatus = ScreenStatus.IDLE;

export const webWalletScreensSubscription = (
  wallet: WalletModule,
  accountInstance: AccountModule
) => {
  Object.entries(WALLET_SCREENS).forEach(([screen, callbackStr]) => {
    const walletCallback = get(wallet, callbackStr);

    if (typeof walletCallback === "function") {
      const event = getUMEvent(screen as UMEventsWallet);

      mediator.subscribe(event.trigger, async () => {
        if (screenStatus === ScreenStatus.PENDING) {
          return;
        }

        screenStatus = ScreenStatus.PENDING;
        try {
          await walletCallback();
          const user = await accountInstance.getUserAsync("Silent");
          const session = await accountInstance.getSessionAsync("Silent");

          screenStatus = ScreenStatus.COMPLETED;

          mediator.dispatch({
            type: event.completion,
            payload: {
              user,
              session
            }
          });
        } catch (e) {
          console.error("[UM] Wallet Library error: ", e);
          screenStatus = ScreenStatus.IDLE;
        }
      });
    }
  });
};
