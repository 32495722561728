import { Event, LogLevel } from "@fanduel/core-events-contract";
import { attempt, isError } from "lodash";

const loggerMap = {
  [LogLevel.DEBUG]: "#6c757d",
  [LogLevel.INFO]: "#498afb",
  [LogLevel.NOTICE]: "#4caf50",
  [LogLevel.WARN]: "#ffc107",
  [LogLevel.ERROR]: "#f44336",
  [LogLevel.CRITICAL]: "#9c27b0"
};

export const logger = (log: Event) => {
  const data = attempt(JSON.parse, log.data);
  const backgroundColor = loggerMap[log.logLevel || LogLevel.INFO];
  console.log(
    `%c[UM - ${log.name}]:%c `,
    `color: #fff; background: ${backgroundColor}; font-weight: bold; padding: 2px;`,
    "color: inherit;",
    isError(data) ? log.data : data
  );
};
