import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { formatPastRaceDate } from "@tvg/formatter/dates";
import { getWagerProfile, getDateRace } from "@urp/store-selectors";
import { TrackDetails } from "../types";
import { TRACKS_FILTER_OPTIONS } from "../constants";
import { PAST_TRACKS_QUERY } from "../graphql/queries/PastTracksQuery";
import { getFavoriteTracks } from "../redux/selectors";

const getTracksOptions = (pastTracksByDate: Array<TrackDetails>) => {
  const tracksOptions = pastTracksByDate.map(
    (track: { code: string; name: string }) => ({
      value: track.code,
      label: track.name
    })
  );

  return [TRACKS_FILTER_OPTIONS.ALL_TRACKS].concat(tracksOptions);
};

const useResultsSearchByTrack = (onlyFavorites: boolean) => {
  const wagerProfile = useSelector(getWagerProfile);
  const currentRaceDate = useSelector(getDateRace);
  const favoriteTracks = useSelector(getFavoriteTracks);
  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const [allTracksDetails, setAllTracksDetails] = useState<Array<TrackDetails>>(
    []
  );
  const [selectedTrack, setSelectedTrack] = useState<string>(
    TRACKS_FILTER_OPTIONS.ALL_TRACKS.value
  );

  const [isLoading, setIsLoading] = useState(false);

  const defaultVariables = useMemo(
    () => ({
      wagerProfile,
      filterBy: {},
      allTracks: true,
      byDate: true,
      byCode: false,
      date: "",
      trackCode: "",
      withDates: false
    }),
    [wagerProfile]
  );

  const filteredTracksDetails = useMemo(
    () =>
      onlyFavorites
        ? allTracksDetails.filter((track) =>
            favoriteTracks.includes(track.code)
          )
        : allTracksDetails,
    [allTracksDetails, onlyFavorites, favoriteTracks]
  );

  const tracksOptions = useMemo(
    () => getTracksOptions(filteredTracksDetails),
    [filteredTracksDetails]
  );

  const selectedTrackDetails = useMemo(
    () => filteredTracksDetails.find((track) => track.code === selectedTrack),
    [filteredTracksDetails, selectedTrack]
  );

  const availableDates = useMemo(() => {
    if (selectedTrack === TRACKS_FILTER_OPTIONS.ALL_TRACKS.value) {
      return [currentRaceDate];
    }
    return selectedTrackDetails?.dates || [];
  }, [selectedTrackDetails, selectedTrack, currentRaceDate]);

  const [getTracksDetails, { loading: tracksLoading }] = useLazyQuery(
    PAST_TRACKS_QUERY,
    {
      onCompleted: (data: { allPastTracks: Array<TrackDetails> }) => {
        setAllTracksDetails(data.allPastTracks);
        setIsLoading(false);
      }
    }
  );

  const [getTrackDates, { loading: datesLoading }] = useLazyQuery(
    PAST_TRACKS_QUERY,
    {
      onCompleted: (data: { pastTrackByTrackCode: Array<TrackDetails> }) => {
        const trackWithDates = data.pastTrackByTrackCode.find(
          (track) => track.code === selectedTrack
        );

        if (trackWithDates) {
          setAllTracksDetails((prevTracks) =>
            prevTracks.map((track) =>
              track.code === selectedTrack
                ? { ...track, dates: trackWithDates.dates }
                : track
            )
          );
        }

        setIsLoading(false);
      }
    }
  );

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(currentRaceDate);
    }
  }, [currentRaceDate]);

  useEffect(() => {
    setIsLoading(true);
    getTracksDetails({
      variables: { ...defaultVariables, wagerProfile }
    });
  }, [wagerProfile]);

  useEffect(() => {
    setIsLoading(tracksLoading || datesLoading);
  }, [tracksLoading, datesLoading]);

  // Update selected date when available dates change
  useEffect(() => {
    if (
      availableDates.length > 0 &&
      (!selectedDate ||
        !availableDates.some((date) => date.includes(selectedDate)))
    ) {
      setSelectedDate(formatPastRaceDate(availableDates[0]));
    }
  }, [availableDates, selectedDate]);

  useEffect(() => {
    const isSelectedTrackAvailable = filteredTracksDetails.some(
      (track) => track.code === selectedTrack
    );

    if (
      !isSelectedTrackAvailable &&
      selectedTrack !== TRACKS_FILTER_OPTIONS.ALL_TRACKS.value
    ) {
      setSelectedTrack(TRACKS_FILTER_OPTIONS.ALL_TRACKS.value);
      setSelectedDate(currentRaceDate);
    }
  }, [filteredTracksDetails]);

  const handleSelectedTrack = useCallback(
    (trackCode: string) => {
      setSelectedTrack(trackCode);

      if (trackCode === TRACKS_FILTER_OPTIONS.ALL_TRACKS.value) {
        setSelectedDate(currentRaceDate);
      } else {
        const newSelectedTrack = filteredTracksDetails.find(
          (track) => track.code === trackCode
        );

        if (!newSelectedTrack?.dates) {
          setIsLoading(true);
          getTrackDates({
            variables: {
              ...defaultVariables,
              trackCode,
              byCode: true,
              withDates: true,
              allTracks: false
            }
          });
        }
      }
    },
    [filteredTracksDetails, currentRaceDate, wagerProfile, getTrackDates]
  );

  return {
    selectedDate,
    selectedTrack,
    selectedTrackDetails,
    tracksDetails: filteredTracksDetails,
    tracksOptions,
    availableDates,
    setSelectedDate,
    setSelectedTrack: handleSelectedTrack,
    isLoading
  };
};

export default useResultsSearchByTrack;
