import { UMPages } from "./types";

export const UM_MAPPED_PAGES = {
  [UMPages.ACCOUNT]: {
    title: "Account",
    path: "/account"
  },
  [UMPages.TRANSACTIONS]: {
    title: "Transactions",
    path: "/account/transactions"
  },
  [UMPages.TAX_INFORMATION]: {
    title: "Tax Information",
    path: "/account/tax-information"
  },
  [UMPages.DEPOSIT]: {
    title: "Deposit",
    path: "/account/deposit"
  },
  [UMPages.WITHDRAWALS]: {
    title: "Withdrawals",
    path: "/withdrawals"
  },
  [UMPages.RESPONSIBLE_PLAY]: {
    title: "Responsible Play",
    path: "/responsible-play"
  },
  [UMPages.ACTIVITY_STATEMENT]: {
    title: "Activity Statement",
    path: "/account/activity-statement"
  },
  [UMPages.TAX_CENTER]: {
    title: "Tax Center",
    path: "/account/tax-center"
  },
  [UMPages.TERMS_AND_CONDITIONS]: {
    title: "Terms and Conditions",
    path: "/terms"
  },
  [UMPages.MY_SPEND]: {
    title: "My Spend",
    path: "/my-spend"
  }
};
