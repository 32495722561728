import type { IModalPresenter } from "@fanduel/core-modal-presenter-contract";
import { MutableRefObject } from "react";
import {
  AccountModule,
  CoreWebviewModule
} from "../../../../../useLoadModule/types";
import { webModalPresenterSubscription } from "./modalPresenter";
import { ExtraProps } from "./types";

export const webModalPresenterSubscriptions = (
  modalPresenter: IModalPresenter,
  CoreWebView: CoreWebviewModule,
  accountInstance: AccountModule,
  extraProps: MutableRefObject<ExtraProps>
) => {
  webModalPresenterSubscription(
    modalPresenter,
    CoreWebView,
    accountInstance,
    extraProps
  );
};
