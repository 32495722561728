import { get } from "lodash";
import { presentModal } from "../presentModal";
import { UM_MAPPED_PAGES } from "./pages";
import { UMPages } from "./types";

export const presentUMModal = (page: UMPages) => {
  const { title, path } = get(UM_MAPPED_PAGES, page, {});
  presentModal({
    title,
    url: path,
    fetchDependencies: {
      needToFetchUserDetails: true,
      needToFetchBalance: true
    }
  });
};
