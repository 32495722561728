import { UnaryFn } from "@tvg/ts-types/Functional";
import { Paragraph } from "@tvg/design-system/web";
import React from "react";
import {
  Container,
  TextContainer,
  CircleRadio,
  StyledLabel,
  HiddenRadio
} from "./styled-components";

interface Props {
  title: string;
  description: string;
  onClick: UnaryFn<string, void>;
  isActive?: boolean;
  qaLabel?: string;
}

function TemplateItem({
  title,
  description,
  onClick,
  isActive = false,
  qaLabel
}: Props) {
  return (
    <Container
      onClick={() => onClick(title)}
      role="button"
      isActive={isActive}
      data-qa-label={qaLabel}
    >
      <HiddenRadio
        name="template-radio-group"
        checked={isActive}
        onChange={() => onClick(title)}
        id={title}
      />

      <StyledLabel htmlFor={title} isActive={isActive}>
        <CircleRadio isActive={isActive} />
        <TextContainer>
          <Paragraph
            fontFamily="medium"
            fontSize="m"
            color={
              isActive ? "--fd-colors-core-blue" : "--fd-colors-core-grey-d-4"
            }
            qaLabel="template-item-title"
          >
            {title}
          </Paragraph>
          <Paragraph
            fontSize="s"
            color="--fd-colors-core-grey"
            qaLabel="template-item-description"
            marginTop={2}
          >
            {description}
          </Paragraph>
        </TextContainer>
      </StyledLabel>
    </Container>
  );
}

export default TemplateItem;
