import { UnifiedModules } from "../../hooks";
import type { ReduxState, ModuleMapToState } from "./types";
import type { Actions } from "../actions/types";

const moduleInitializedWhenImport = [UnifiedModules.CORE_CONFIG];

export const initialState: ReduxState = Object.values(UnifiedModules).reduce(
  (modules, module: UnifiedModules) => ({
    ...modules,
    [module]: {
      isInitialized: moduleInitializedWhenImport.includes(module)
    }
  }),
  {} as ModuleMapToState
);

export default function unifiedModulesReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ReduxState = initialState,
  action: Actions
) {
  switch (action.type) {
    case "SET_UM_INITIALIZED": {
      const { module } = action.payload;

      return {
        ...state,
        [module]: {
          ...state[module],
          isInitialized: true
        }
      };
    }
    case "SET_UM_CORE_CONFIG_STATE": {
      return {
        ...state,
        CoreConfig: {
          ...state.CoreConfig,
          state: action.payload.state
        }
      };
    }
    case "SET_ACCOUNT_MODAL_IS_OPENED": {
      return {
        ...state,
        Account: {
          ...state.Account,
          isOpened: action.payload.isOpened
        }
      };
    }
    default: {
      return state;
    }
  }
}
