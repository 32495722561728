import { getUMEvent } from "../../../../../../utils/mediator/events";
import mediator from "../../../../../../utils/mediator";
import { updateUserDataCallback } from "../completion/utils/updateUserData";
import type { UMEventsWallet } from "../../../../../../utils/mediator/types";
import { WALLET_SCREENS } from ".";

export const walletScreensSubscription = () => {
  Object.keys(WALLET_SCREENS).forEach((screen) => {
    const event = getUMEvent(screen as UMEventsWallet);
    mediator.subscribe(event.completion, updateUserDataCallback);
  });
};
