import { get } from "lodash";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import mediatorScoped from "../../../../../../../utils/mediator";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import type { AcceptTermsSubscriptionPayload } from "./types";
import type { AccountModule } from "../../../../../../useLoadModule/types";

export const webAcceptTermsSubscription = (accountInstance: AccountModule) => {
  const event = getUMEvent(UMEvents.AcceptTerms);

  mediatorScoped.subscribe(
    event.trigger,
    async (data: AcceptTermsSubscriptionPayload) => {
      const isAccepted = get(data, "payload.accepted", false);
      const showUI = get(data, "payload.showUI", false);
      const needsUpdate = !isAccepted && showUI;

      if (showUI) {
        await accountInstance.acceptTermsAsync();
      }

      mediatorScoped.dispatch({
        type: event.completion,
        payload: {
          isAccepted,
          needsUpdate
        }
      });
    }
  );
};
