import { Card } from "@fdr-mar/promos-types/Component";
import { StoreCPPPromosAction } from "./types";

export type State = {
  promotions: Card[];
};

export const initialState = {
  promotions: []
};

// eslint-disable-next-line
export default (state: State = initialState, action: StoreCPPPromosAction) => {
  switch (action.type) {
    case "STORE_CPP_PROMOS": {
      return { ...state, promotions: action.payload };
    }
    default: {
      return state;
    }
  }
};
