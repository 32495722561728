import React, { useMemo, useCallback } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { LinkProps } from "react-router-dom";
import TVGConf from "@tvg/conf";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { Paragraph, Logo } from "@tvg/design-system";
import { setShowStateSelector } from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import { isUserInAWFlows } from "@tvg/sh-lib-account-actions/src/selectors/user-ts";
import { getResidenceState, getIsLogged } from "@urp/store-selectors";
import mediator from "@tvg/mediator";
import {
  checkIsValidPageByQaLabel,
  presentModalByQaLabel
} from "@urp/unified-modules/src/utils/modalPresenter/presentModalByQaLabel";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { setGlobalCallback } from "@tvg/sh-utils/callbackUtils";

import {
  Container,
  LinksContainer,
  List,
  ListItem,
  LogosContainer,
  SocialIcon,
  SocialIcons,
  StyledLink,
  WarningContainer
} from "./styled-components";
import defaultData from "./defaultData.json";
import { FanduelLogo, Facebook, Youtube, Twitter } from "./icons";

type Item = {
  text: string;
  link: string;
};

const Footer = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector(getIsLogged);
  const isAWFlowEnabled = useSelector(isUserInAWFlows);
  const userHomeState = useSelector(getResidenceState);
  const tvgFooter = useSelector((store) =>
    parseCapiMessage(store, `capi.messages.TVG5footer`, defaultData)
  );
  const iowaFooter = useSelector((store) =>
    parseCapiMessage(store, `capi.messages.iowaFooter`, defaultData)
  );
  const njbetsFooter = useSelector((store) =>
    parseCapiMessage(store, `capi.messages.4njbetsFooter`, defaultData)
  );
  const fdrFooter = useSelector((store) =>
    parseCapiMessage(store, `capi.messages.FDRfooter`, defaultData)
  );

  const logoBrand = useMemo(
    () =>
      TVGConf().brand === "fdr" && userHomeState === "IA"
        ? "fdr-iowa"
        : TVGConf().brand,
    [userHomeState]
  );

  const colorTokens = useColorTokens();

  const brand = get(TVGConf(), "brand", "tvg");

  const data = useMemo(() => {
    switch (brand) {
      case "4njbets":
        return njbetsFooter;
      case "iowa":
        return iowaFooter;
      case "fdr":
        return fdrFooter;
      default:
        return tvgFooter;
    }
  }, [brand, tvgFooter, fdrFooter, iowaFooter, njbetsFooter]);

  const leftLinks = get(data, "leftColumn.links", []);
  const rightLinks = get(data, "rightColumn.links", []);

  const onClickLink = useCallback(
    (text: string, link: string, qaLabel: string) => {
      const onClickHandler = () => {
        mediator.base.dispatch({
          type: "FOOTER_CLICK_LINKS",
          payload: {
            linkName: text,
            destinationUrl: link
          }
        });
      };
      if (isAWFlowEnabled && checkIsValidPageByQaLabel(qaLabel)) {
        presentModalByQaLabel(qaLabel);
      } else if (qaLabel === "terms-and-conditions" && !isLogged) {
        dispatch(setShowStateSelector(true));
        setGlobalCallback(() => {
          onClickHandler();
          window.open(link, "_blank");
        });
      } else {
        onClickHandler();
      }
    },
    [isLogged]
  );

  const renderColumnLinks = useCallback(
    (links, sliceFrom = 0, sliceTo = 15) =>
      links
        .slice(sliceFrom, sliceTo)
        .map(({ text, link }: Item, index: number) => {
          const isExternalLink = link.indexOf("https://") === 0;
          const qaLabel = text.replace(/\s+/g, "-").toLowerCase();
          const isTermsAndConditions =
            qaLabel === "terms-and-conditions" && !isLogged;

          const linkProps = isExternalLink
            ? {
                as: "a",
                href: link,
                target: isTermsAndConditions ? "" : "_blank",
                rel: isTermsAndConditions ? "" : "noopener noreferrer"
              }
            : { to: link };

          const handleClick = (
            e: React.MouseEvent<HTMLLIElement, MouseEvent>
          ) => {
            if (isTermsAndConditions) {
              e.preventDefault();
            }
            onClickLink(text, link, qaLabel);
          };

          return (
            <ListItem key={index.toString()} onClick={handleClick}>
              <StyledLink
                data-qa-label={`${qaLabel}-link`}
                {...(linkProps as LinkProps)}
              >
                <Paragraph
                  fontSize="xs"
                  color={colorTokens.content.subtle}
                  qaLabel={`${qaLabel}-link-text`}
                >
                  {text}
                </Paragraph>
              </StyledLink>
            </ListItem>
          );
        }),
    [data, onClickLink]
  );

  const socialLinks = useMemo(
    () =>
      Object.keys(data.socialLinks).map((item) => ({
        link: data.socialLinks[`${item}`],
        text: item
      })),
    [data.socialLinks]
  );

  const handleSocialIcon = (name: string) => {
    switch (name) {
      case "facebook":
        return <Facebook />;
      case "twitter":
        return <Twitter />;
      default:
        return <Youtube />;
    }
  };

  return (
    <Container data-qa-label={`${brand}-footer-container`}>
      <WarningContainer>
        <Paragraph
          fontSize="xs"
          color={colorTokens.content.subtle}
          textTransform="uppercase"
          qaLabel="footer-warning-text"
        >
          {data.warning}
        </Paragraph>
      </WarningContainer>
      <LinksContainer
        data-qa-label="footer-links"
        numberOfColumns={rightLinks.length >= 15 ? 3 : 2}
      >
        <List data-qa-label="footer-left-column">
          <Paragraph
            fontSize="xs"
            color={colorTokens.content.strong}
            fontFamily="medium"
            qaLabel="footer-left-column-title"
            mb="space-5"
          >
            {data.leftColumn.title}
          </Paragraph>
          {renderColumnLinks(leftLinks, 0, 15)}
          <SocialIcons data-qa-label="footer-social-icons">
            {socialLinks &&
              socialLinks.map(({ text, link }, index) => (
                <SocialIcon
                  key={index.toString()}
                  data-qa-label={`${text}-link`}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {handleSocialIcon(text)}
                </SocialIcon>
              ))}
          </SocialIcons>
        </List>
        <List data-qa-label="footer-right-column">
          <Paragraph
            fontSize="xs"
            color={colorTokens.content.strong}
            fontFamily="medium"
            qaLabel="footer-right-column-title"
            mb="space-5"
          >
            {data.rightColumn.title}
          </Paragraph>
          {renderColumnLinks(rightLinks, 0, 15)}
        </List>
        {rightLinks.length >= 15 && (
          <List data-qa-label="footer-right-column-p2" hasPaddingTop>
            {renderColumnLinks(rightLinks, 15, 30)}
          </List>
        )}
      </LinksContainer>
      <LogosContainer data-qa-label="logos-container">
        <Logo qaLabel={`${brand}-logo`} brand={logoBrand} />
        <FanduelLogo />
      </LogosContainer>
    </Container>
  );
};

export default Footer;
