import { WalletModule } from "../../../../../../useLoadModule/types";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import mediator from "../../../../../../../utils/mediator";

export const webGetBalanceSubscription = (wallet: WalletModule) => {
  const event = getUMEvent(UMEvents.GetBalance);

  mediator.subscribe(event.trigger, async () => {
    const balances = await wallet.getBalancesAsync();

    mediator.dispatch({
      type: event.completion,
      payload: {
        balances
      }
    });
  });
};
