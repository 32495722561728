import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { noop } from "lodash";
import { getIsLogged, getIsProgramPageVideoOpen } from "@urp/store-selectors";
import { keepAliveSessionCallback } from "../utils/keepAliveCallback";

const VIDEO_INTERVAL_UPDATE = 60000; // 1m

export const useKeepUserSessionAliveByVideo = () => {
  const isLogged = useSelector(getIsLogged);
  const isProgramPageVideoOpen = useSelector(getIsProgramPageVideoOpen);
  const [intervalId, setIntervalId] = useState<number | null>(null);

  const clearIntervalId = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  useEffect(() => {
    if (typeof window === "undefined") return noop;

    clearIntervalId();

    const id = setInterval(() => {
      if (isProgramPageVideoOpen) {
        keepAliveSessionCallback(isLogged);
      }
    }, VIDEO_INTERVAL_UPDATE);

    setIntervalId(id);

    return clearIntervalId;
  }, [isProgramPageVideoOpen, isLogged]);
};
