import { Dispatch } from "redux";
import { get } from "lodash";
import {
  setIsVerified,
  setUserTermsAndCondition
} from "@fdr/shared-actions/UserActions";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import mediatorScoped from "../../../../../../../utils/mediator";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import { fetchCompliance } from "../../../../../../../utils/account/getUserCompliance/service";
import type { VerifyUserPayload } from "./types";

export const verifyUserSubscription = (dispatch: Dispatch) => {
  const event = getUMEvent(UMEvents.Verification);

  mediatorScoped.subscribe(
    event.completion,
    async (data: VerifyUserPayload) => {
      const needsUpdate = get(data, "payload.needsUpdate", false);
      let currentVerifiedStatus = false;
      if (!needsUpdate) {
        currentVerifiedStatus = get(data, "payload.isVerified", false);
      } else {
        const { accepted, isVerified } = await fetchCompliance();
        currentVerifiedStatus = !!isVerified;
        dispatch(setUserTermsAndCondition(!!accepted));
      }

      dispatch(setIsVerified(currentVerifiedStatus));
    }
  );
};
