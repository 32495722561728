import type { MutableRefObject } from "react";
import type { Dispatch } from "redux";

import type { AccountModule } from "../../../../../useLoadModule/types";
import { webAcceptTermsSubscription } from "./acceptTerms";
import { webHomepageSubscription } from "./homepage";
import { webKeepAliveSubscription } from "./keepAlive";
import { webLogoutSubscription } from "./logout";
import { webOpenAuthenticationSubscription } from "./openAuthentication";
import { webVerifyUserSubscription } from "./verifyUser";
import { ExtraProps } from "./types";

export const webAccountSubscriptions = (
  account: AccountModule,
  extraProps: MutableRefObject<ExtraProps>,
  dispatch: Dispatch
) => {
  webAcceptTermsSubscription(account);
  webHomepageSubscription(account);
  webKeepAliveSubscription(account);
  webLogoutSubscription(account);
  webOpenAuthenticationSubscription(account, extraProps, dispatch);
  webVerifyUserSubscription(account);
};
