import mediatorScoped from "../../../../../../../utils/mediator";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import { AccountModule } from "../../../../../../useLoadModule/types";
import { getUMEvent } from "../../../../../../../utils/mediator/events";

export const webKeepAliveSubscription = (accountInstance: AccountModule) => {
  const event = getUMEvent(UMEvents.KeepAlive);

  mediatorScoped.subscribe(event.trigger, () => {
    accountInstance.keepAlive();
  });
};
