import { UnifiedModules } from "../../hooks/useLoadModule/types";
import { SetModuleInitializedAction } from "./types";

export const setModuleInitializedAction = (
  module: UnifiedModules
): SetModuleInitializedAction => ({
  type: "SET_UM_INITIALIZED",
  payload: {
    module
  }
});

export const setCoreConfigState = (region: string) => ({
  type: "SET_UM_CORE_CONFIG_STATE",
  payload: {
    state: region
  }
});

export const setAccountModalIsOpened = (isOpened: boolean) => ({
  type: "SET_ACCOUNT_MODAL_IS_OPENED",
  payload: {
    isOpened
  }
});
