import type {
  AccountModule,
  WalletModule
} from "../../../../../useLoadModule/types";
import { webGetBalanceSubscription } from "./getBalance";
import { webWalletScreensSubscription } from "../screensMapping/webScreensSubscription";

export const webWalletSubscriptions = (
  wallet: WalletModule,
  accountInstance: AccountModule
) => {
  webGetBalanceSubscription(wallet);

  // This subscription have all wallet screens,
  // if needed override please remove from screen mapping and create a new subscription function
  // also remember to change the completion subscriptions
  webWalletScreensSubscription(wallet, accountInstance);
};
