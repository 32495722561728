import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { isSameDay } from "date-fns";
import { Track } from "@tvg/ts-types/Track";
import { getWagerProfile, getDateRace } from "@urp/store-selectors";
import { RESULTED_RACES_STATUS, TRACKS_FILTER_OPTIONS } from "../constants";
import { TrackDetails } from "../types";
import { getFavoriteTracks } from "../redux/selectors";
import useRaceTracks from "./useRaceTracks";
import { PAST_TRACKS_QUERY } from "../graphql/queries/PastTracksQuery";

const getTracksOptions = (
  pastTracksByDate: Array<TrackDetails>,
  isTodayResults = false
) => {
  if (pastTracksByDate.length === 0) {
    return [TRACKS_FILTER_OPTIONS.NO_TRACKS];
  }

  const tracksOptions = pastTracksByDate.map(
    (track: { code: string; name: string }) => ({
      value: track.code,
      label: track.name
    })
  );

  return isTodayResults
    ? [TRACKS_FILTER_OPTIONS.ALL_TRACKS].concat(tracksOptions)
    : tracksOptions;
};

const filterTracksByTodayRaces = (
  todayTracksRaces: Array<Track>,
  tracksDetails: Array<TrackDetails>
) => {
  const todayTracksCodes = todayTracksRaces.map((track) => track.code);
  return tracksDetails.filter((track) => todayTracksCodes.includes(track.code));
};

const useResultsSearchByDate = (onlyFavorites: boolean) => {
  const currentRaceDate = useSelector(getDateRace);
  const [selectedDate, setSelectedDate] = useState<string>();
  const favoriteTracks = useSelector(getFavoriteTracks);
  const wagerProfile = useSelector(getWagerProfile);

  const [isTodayResults, setIsTodayResults] = useState<boolean>(true);
  const [selectedTrack, setSelectedTrack] = useState<string>(
    TRACKS_FILTER_OPTIONS.ALL_TRACKS.value
  );
  const [allTracksDetails, setAllTracksDetails] = useState<Array<TrackDetails>>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const { tracksRaces: todayTracksRaces } = useRaceTracks({
    raceStatusFilter: RESULTED_RACES_STATUS
  });

  const defaultVariables = {
    wagerProfile,
    filterBy: {},
    allTracks: false,
    byDate: true,
    byCode: false,
    date: "",
    trackCode: "",
    withDates: false
  };

  const [getTracksDetails, { loading }] = useLazyQuery(PAST_TRACKS_QUERY, {
    onCompleted: (data: { pastTracksByDate: Array<TrackDetails> }) => {
      const newPastTracksByDate =
        isTodayResults && todayTracksRaces?.length > 0
          ? filterTracksByTodayRaces(todayTracksRaces, data.pastTracksByDate)
          : data.pastTracksByDate;

      setAllTracksDetails(newPastTracksByDate);
      setIsLoading(false);
    }
  });

  const filteredTracksDetails = useMemo(() => {
    let filtered = [...allTracksDetails];

    if (isTodayResults && todayTracksRaces?.length > 0) {
      filtered = filterTracksByTodayRaces(todayTracksRaces, filtered);
    }

    if (onlyFavorites) {
      filtered = filtered.filter((track) =>
        favoriteTracks.includes(track.code)
      );
    }

    return filtered;
  }, [
    allTracksDetails,
    isTodayResults,
    JSON.stringify(todayTracksRaces),
    onlyFavorites,
    JSON.stringify(favoriteTracks)
  ]);

  const tracksOptions = useMemo(
    () => getTracksOptions(filteredTracksDetails, isTodayResults),
    [filteredTracksDetails, isTodayResults]
  );

  const selectedTrackDetails = useMemo(
    () => filteredTracksDetails.find((track) => track.code === selectedTrack),
    [filteredTracksDetails, selectedTrack]
  );

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(currentRaceDate);
    }
  }, [currentRaceDate]);

  useEffect(() => {
    setIsLoading(true);
    getTracksDetails({
      variables: { ...defaultVariables, wagerProfile, date: selectedDate }
    });
  }, [selectedDate, wagerProfile]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (tracksOptions.length > 0) {
      const firstTrackOption = tracksOptions[0];
      setSelectedTrack(firstTrackOption.value);
    }
  }, [tracksOptions]);

  const handleSelectedDate = useCallback(
    (newDate: string) => {
      setSelectedDate(newDate);
      setIsTodayResults(
        isSameDay(new Date(currentRaceDate), new Date(newDate))
      );
    },
    [currentRaceDate]
  );

  return {
    selectedDate,
    selectedTrack,
    selectedTrackDetails,
    tracksDetails: filteredTracksDetails,
    tracksOptions,
    setSelectedDate: handleSelectedDate,
    setSelectedTrack,
    isLoading
  };
};

export default useResultsSearchByDate;
