export const userInitialData = {
  error: false,
  hasRequested: false,
  logged: false,
  logging: false,
  user: {
    accountNumber: "",
    emailAddress: "",
    firstName: "",
    homeAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateAbbr: ""
    },
    lastName: "",
    mailingAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateAbbr: ""
    },
    primaryPhone: "",
    profile: "",
    signalProviderId: "",
    status: "",
    transportId: "",
    userName: "",
    wagerStatus: 0,
    accountRoles: ["USER"]
  },
  preferences: {},
  gotPreferences: false,
  gotPromos: false,
  loadingPromos: false,
  optedInPromos: {},
  wasLogin: false,
  balance: null,
  promoFunds: 0,
  balancePoller: 120,
  returningUser: false,
  brazeContentCards: {
    unviewed: 0,
    totalCards: 0,
    cards: []
  },
  isVerified: undefined,
  jurisdiction: undefined,
  isLoginError: false,
  sessionStartAt: undefined
};
